import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { Combat, Fighter } from '../../store/types/fighterTypes';
import { eventCloud } from '../../eventCloud';
import { Button } from '../Button/Button';
import { useNavigate } from 'react-router-dom';

import { RoundTargetHits } from '../Combat/RoundTargetHits';
import { ReactComponent as AttackIcon } from '../../assets/icons/svg/attack.svg';
import { ReactComponent as DefenceIcon } from '../../assets/icons/svg/defence.svg';
import { ReactComponent as HealthIcon } from '../../assets/icons/svg/health.svg';

type CombatDrawProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	combat: Combat;
};

export const CombatDraw = forwardRef<HTMLButtonElement, CombatDrawProps>(({ combat, ...rest }, ref) => {
	const navigate = useNavigate();

	const handleReturnToLobby = () => {
		navigate('/profile'); // Navigate to /lobby when the button is clicked
	};

	const getWager = (): number => {
		return Math.min(combat.maker_wager, combat.taker_wager);
	};

	return (
		<>
			{getWager() > 0 && (
				<div
					className="w-full gap-y-1 p-2 bg-cover bg-center border-gray-550 lg:p-4 mb-2"
					style={{
						backgroundImage: "url('/assets/--combat-abandonned-bg.png')",
					}}
				>
					<div className="text-center text-white">Your bet has not paid off...</div>
					<div className="text-center font-bold text-yellow-500 text-2xl">-{getWager() * 0.1} CR</div>
				</div>
			)}
			<div
				className="w-full p-8 bg-cover bg-center border border-gray-550 rounded-sm"
				style={{
					backgroundImage: "url('/assets/--combat-abandonned-bg.png')",
				}}
			>
				<div className="flex flex-col h-full justify-between">
					<div className="flex flex-col items-center justify-center flex-grow">
						{' '}
						{/* Add flex, justify-center, and items-center */}
						<div className="text-xxs md:text-2xl text-center">Battle Finished in</div>
						<div className="text-4xl text-white font-bold mt-4">DRAW</div>
					</div>

					<div className="mt-auto">
						<Button
							size="large"
							variant="black"
							onClick={handleReturnToLobby}
							className="mt-4 px-4 py-4 text-2xl"
						>
							RETURN HOME
						</Button>
					</div>
				</div>
			</div>
		</>
	);
});

CombatDraw.displayName = 'CombatDraw';

// src/pages/HistoryPage.tsx

import React, { useState, useEffect, useRef } from 'react';
import { eventCloud } from '../../eventCloud';
import { Fighter, Combat } from '../../store/types/fighterTypes';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../Button/Button';
import classNames from 'classnames';

import { ReactComponent as AttackIcon } from '../../assets/icons/svg/attack.svg';
import { FighterPopover } from '../Fighter/FighterPopover';

type HistoryProps = {
	fighter: Fighter | null;
};

const History: React.FC<HistoryProps> = ({ fighter }) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [selectedItem, setSelectedItem] = useState<Fighter | null>(null);
	const [finishedCombats, setFinishedCombats] = useState<Combat[] | null>(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (!fighter) return;

		console.log('history fighter', fighter);
		eventCloud.requestCombatsHistory();
	}, [fighter]);

	useEffect(() => {
		const handleCombatsHistory = (combatHistory: Combat[]) => {
			console.log('combatHistory', combatHistory);
			setFinishedCombats(combatHistory);
		};

		eventCloud.on('combats_history', handleCombatsHistory);

		const handleWalletChanged = () => {
			console.log('handleWalletChanged');
			setFinishedCombats(null);
		};

		eventCloud.on('wallet_selected', handleWalletChanged);

		return () => {
			eventCloud.off('combats_history', handleCombatsHistory);
			eventCloud.off('wallet_selected', handleWalletChanged);
		};
	}, []);

	return (
		<div className="h-full">
			<FighterPopover
				anchorEl={anchorEl}
				fighter={selectedItem}
				hideHealth={true}
				showItemLevels={false}
				onClose={() => {
					setSelectedItem(null);
					setAnchorEl(null);
				}}
			/>
			<div className="flex flex-col h-full">
				<div className="flex flex-col">
					<div className="h-full">
						<div className="h-full flex flex-col">
							<table className="w-full table-fixed">
								<thead className="bg-gray-550 bg-opacity-50 text-gray-200 font-bold text-xxxs xs:text-xxs border-b border-black h-[27px]">
									<tr>
										<th className="text-left xs:w-[120px] w-[80px] pl-2 ">Time</th>
										<th className="text-left w-[60px]">Status</th>
										<th className="text-center w-[80px]  hidden md:!hidden">Type</th>
										<th className="text-center w-[100px] hidden md:!hidden">Levels</th>
										<th className="text-left">Opponent</th>
										<th className="text-right w-[80px] px-2 hidden xs:!hidden">Rounds</th>
										<th className="text-center w-[60px] px-2"></th>
									</tr>
								</thead>
							</table>

							{!finishedCombats ? (
								<div className="text-center text-gray-200 text-m m-10">History empty</div>
							) : (
								<div className="flex-1 overflow-y-auto">
									<table className="w-full table-fixed">
										<tbody className="text-white  text-xxxs xs:text-xxs font-regular bg-gray-550 bg-opacity-50">
											{finishedCombats.map((combat) => (
												<tr key={combat.id} className="border-t border-black">
													<td className=" xs:w-[120px] w-[80px] pl-2 text-white font-mono  text-xxs xs:text-m">
														{formatTimestampToLocaleTime(combat.finished_at)}
													</td>
													<td
														className={classNames(
															`   xs:w-[60px]  font-bold ${
																combat.winner
																	? combat.winner.id == fighter?.id
																		? 'text-blue-550'
																		: 'text-red-550'
																	: combat.is_abandoned
																		? 'text-gray-200'
																		: 'text-yellow-500'
															}`
														)}
													>
														{combat.winner
															? combat.winner.id == fighter?.id
																? 'Victory'
																: 'Defeat'
															: combat.is_abandoned
																? 'Abandon'
																: 'Draw'}
													</td>
													<td className="text-center w-[80px]  hidden md:!hidden">1 v 1</td>
													<td className="text-center w-[100px] font-mono text-m px-2  hidden md:!hidden">
														<div className="flex justify-between">
															<div className="w-[40px]">
																{Math.max(0, combat.maker.level - 1)}
															</div>
															<div className="flex-grow">-</div>
															<div className="w-[40px]">{combat.maker.level + 1}</div>
														</div>
													</td>
													<td>
														<div className="relative flex items-center mr-2 space-x-2 py-1">
															<div className="flex-grow flex font-bold justify-between">
																<div
																	className="flex items-center cursor-pointer"
																	onClick={(e: any) => {
																		setSelectedItem(
																			fighter?.id == combat.maker.id
																				? combat.taker
																				: combat.maker
																		);
																		setAnchorEl(e.currentTarget);
																	}}
																>
																	{fighter?.id == combat.maker.id
																		? combat.taker.name
																		: combat.maker.name}{' '}
																	<span className="text-blue-550">
																		[
																		{fighter?.id == combat.maker.id
																			? combat.taker.level
																			: combat.maker.level}
																		]
																	</span>
																</div>
															</div>
														</div>
													</td>
													<td className="text-right w-[80px] px-2 hidden xs:!hidden">
														<div className="text-gray-200">
															{!combat?.closed_rounds
																? 0
																: Number(combat?.closed_rounds?.length)}
														</div>
													</td>
													<td className="text-right w-[60px] px-2">
														<div>
															<Button
																variant="blue-trans"
																onClick={() => navigate('/combat/' + combat.id)}
																className="py-0 xs:p-1 text-xxxs"
															>
																View
															</Button>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

function formatTimestampToLocaleTime(timestamp: number) {
	return new Date(timestamp).toLocaleTimeString(undefined, {
		day: '2-digit',
		month: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	});
}

export default History;

import classNames from 'classnames';
import React, { forwardRef, useRef, useEffect } from 'react';
import { Combat, Fighter } from '../../store/types/fighterTypes';
import { Tooltip } from 'react-tooltip';

import { RoundTargetHits } from '../Combat/RoundTargetHits';
import { ReactComponent as AttackIcon } from '../../assets/icons/svg/attack.svg';
import { ReactComponent as DefenceIcon } from '../../assets/icons/svg/defence.svg';
import { ReactComponent as HealthIcon } from '../../assets/icons/svg/health.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/svg/close.svg';

import { ReactComponent as HeadIcon } from '../../assets/icons/svg/head.svg';
import { ReactComponent as ChestIcon } from '../../assets/icons/svg/chest.svg';
import { ReactComponent as StomachIcon } from '../../assets/icons/svg/stomach.svg';
import { ReactComponent as CrutchIcon } from '../../assets/icons/svg/crutch.svg';
import { ReactComponent as LegsIcon } from '../../assets/icons/svg/legs.svg';
import { ReactComponent as LogsIcon } from '../../assets/icons/svg/logs.svg';
import { ReactComponent as FightIcon } from '../../assets/icons/svg/fight.svg';

type CombatTapeProps = React.ButtonHTMLAttributes<HTMLDivElement> & {
	combat: Combat;
	side: 'maker' | 'taker';
};

export const CombatTape = forwardRef<HTMLDivElement, CombatTapeProps>(({ combat, side, ...rest }, ref) => {
	const messageEndRef = useRef<HTMLDivElement | null>(null);
	const scrollToBottom = () => {
		if (messageEndRef.current) {
			messageEndRef.current.scrollIntoView({ behavior: 'auto' });
		}
	};

	// useEffect(() => {
	// 	scrollToBottom();
	// }, [combat]);

	return (
		<div className="flex text-xxs h-full min-w-[180px] flex-col gap-y-1">
			{!combat.closed_rounds || combat.closed_rounds.length == 0 ? (
				<div className="flex  w-full  text-gray-200 bg-gray-550 bg-opacity-50 py-1 px-3 justify-between rounded-sm p-1 items-center flex border border-gray-550">
					<div>
						<LogsIcon />
					</div>
					<div>No rounds</div>
				</div>
			) : (
				combat.closed_rounds
					?.slice()
					.reverse()
					.map((round, index) => {
						const damage = side == 'maker' ? round.taker_damage : round.maker_damage;

						const dmgType = side == 'maker' ? round.taker_damage_type : round.maker_damage_type;

						const attack = side == 'maker' ? round.taker_attack : round.maker_attack;

						const defence = side == 'maker' ? round.maker_defence : round.taker_defence;

						const opponentAttackSubmitted = side == 'maker' ? round.taker_submitted : round.maker_submitted;

						const attackSubmitted = side == 'maker' ? round.maker_submitted : round.taker_submitted;

						const attackSuc = attack != defence;

						const skillName = side == 'maker' ? round.taker_skill?.name : round.maker_skill?.name;

						return (
							<div
								key={index}
								className="flex items-center bg-gray-550 bg-opacity-50 py-1 px-1 rounded-sm justify-between"
							>
								<div className="font-bold  w-[30px] font-mono text-center">
									{combat.closed_rounds.length - index}
								</div>
								<div className="flex gap-x-2">
									<div
										className={classNames(
											`whitespace-nowrap text-red-550 rounded-sm p-1 items-center flex border border-red-550 ${attackSuc ? 'border-opacity-100 bg-black' : 'border-opacity-0 bg-red-550 bg-opacity-10'}`
										)}
									>
										{!opponentAttackSubmitted ? (
											<CloseIcon width={12} />
										) : attack == 'Head' ? (
											<HeadIcon width={12} />
										) : attack == 'Chest' ? (
											<ChestIcon width={12} />
										) : attack == 'Stomach' ? (
											<StomachIcon width={12} />
										) : attack == 'Crutch' ? (
											<CrutchIcon width={12} />
										) : (
											attack == 'Legs' && <LegsIcon width={12} />
										)}
									</div>
									<div
										className={classNames(
											`whitespace-nowrap text-blue-550 rounded-sm p-1 flex items-center space-x-2 border border-blue-550 ${!attackSuc ? 'border-opacity-100 bg-black' : 'border-opacity-0 bg-blue-550 bg-opacity-10'}`
										)}
									>
										{!attackSubmitted ? (
											<CloseIcon width={12} />
										) : defence == 'Head' ? (
											<HeadIcon width={12} />
										) : defence == 'Chest' ? (
											<ChestIcon width={12} />
										) : (
											defence == 'Legs' && <LegsIcon width={12} />
										)}
									</div>
								</div>
								<div
									className={`whitespace-nowrap font-bold w-[40px] text-right ${
										damage == 0
											? 'text-gray-200'
											: dmgType == 'Basic'
												? 'text-yellow-500'
												: dmgType == 'Critical'
													? 'text-blue-550'
													: 'text-green-500'
									}`}
								>
									{damage > 0 && '-'}
									{damage}
								</div>

								<div className="ml-1">
									{!opponentAttackSubmitted || skillName == 'Basic' ? (
										<div className="text-gray-550 select-none p-1 rounded-sm bg-black border-gray-550 border w-full h-full flex items-center justify-center">
											<FightIcon
												width={12}
												height={15}
												data-tooltip-id={skillName + '-' + index}
												data-tooltip-content={'Basic Hit'}
											/>
											<Tooltip
												id={skillName + '-' + index}
												className="z-10"
												style={{ fontSize: '10px' }}
												place="top"
											/>
										</div>
									) : (
										<div
											className={classNames(
												`w-[22px] h-[25px] relative rounded-sm border-blue-550 border cursor-pointer flex justify-center`
											)}
										>
											<img
												className="h-full"
												src={`/assets/${skillName}.jpg`}
												data-tooltip-id={skillName + '-' + index}
												data-tooltip-content={skillName}
											/>
											<Tooltip
												id={skillName + '-' + index}
												className="z-10"
												style={{ fontSize: '10px' }}
												place="top"
											/>
										</div>
									)}
								</div>
							</div>
						);
					})
			)}
			<div ref={messageEndRef} />
		</div>
	);
});

CombatTape.displayName = 'CombatTape';

// src/pages/NotFoundPage.tsx
import React from 'react';

const NotFoundPage: React.FC = () => {
	return (
		<div>
			<div style={{ textAlign: 'center', marginTop: '50px' }}>
				<h1>{'404 - Page Not Found'}</h1>
				<p>{"The page you're looking for does not exist."}</p>
			</div>
		</div>
	);
};

export default NotFoundPage;

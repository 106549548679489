// ItemPopover.tsx

import React, { forwardRef, useState, useEffect } from 'react';
import { Fighter } from '../../store/types/fighterTypes';
import Popover from '@mui/material/Popover';
import classNames from 'classnames';
import { eventCloud } from '../../eventCloud';
import FighterProfile from './FighterProfile';

type FighterPopoverProps = {
	anchorEl: HTMLElement | null;
	fighter: Fighter | null;
	onClose: any | null;

	anchor?: any;
	hideHealth?: boolean;
	hideStats?: boolean;
	statsVertical?: boolean;
	showItemLevels?: boolean;
};

export const FighterPopover = forwardRef<HTMLDivElement, FighterPopoverProps>(
	(
		{
			anchorEl,
			fighter,
			anchor = 'right',
			hideHealth = true,
			onClose,
			hideStats = true,
			statsVertical = false,
			showItemLevels = true,
			...rest
		},
		ref
	) => {
		return (
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => {
					onClose();
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: anchor,
				}}
			>
				<div className="p-2 bg-black border border-gray-550 w-[200px] sm:w-[350px]">
					<FighterProfile
						hideStats={hideStats}
						statsVertical={statsVertical}
						fighter={fighter}
						equippable={false}
						showItemLevels={showItemLevels}
						hideHealth={hideHealth}
					/>
				</div>
			</Popover>
		);
	}
);

FighterPopover.displayName = 'FighterPopover';

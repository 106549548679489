// notificationHelper.tsx

import React from 'react';

interface MessageSegment {
	text: string;
	bold: boolean;
}

interface NotificationData {
	title: string;
	message: MessageSegment[];
}

export const formatNotificationMessage = (notification: NotificationData) => {
	return (
		<>
			<strong>{notification.title}</strong>
			<p>
				{notification.message.map((segment, index) =>
					segment.bold ? <b key={index}>{segment.text}</b> : <span key={index}>{segment.text}</span>
				)}
			</p>
		</>
	);
};

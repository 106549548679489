// getClassName.ts

export function getClassName(c: number): string {
	switch (c) {
		case 0:
			return 'Knight';
		case 1:
			return 'Wizard';
	}

	return c + '';
}

// src/pages/InventoryPage.tsx
import React, { useState, useEffect } from 'react';
import Inventory from '../components/Inventory/Inventory';
import FighterProfile from '../components/Fighter/FighterProfile';
import { Account, Fighter } from '../store/types/fighterTypes';

type InventoryPageProps = {
	fighter: Fighter | null;
	account: Account | null;
};

const InventoryPage: React.FC<InventoryPageProps> = ({ account, fighter }) => {
	return (
		<div className="flex">
			<div className="flex-grow">
				<Inventory account={account} fighter={fighter} />
			</div>
			<div className="mr-5">
				<FighterProfile statsUpdateable={true} fighter={fighter} equippable={true} allowItemPopover={true} />
			</div>
		</div>
	);
};

export default InventoryPage;

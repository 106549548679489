import Web3 from 'web3';
import { requestAccountAccess } from '../helpers/requestAccountAccess';
import { Wallet } from '../store/types/fighterTypes';
import { eventCloud } from './../eventCloud';

export const SignMessage = async (
	message: string, // Change from hash to message
	wallet: Wallet
): Promise<{ v: number; r: string; s: string } | null> => {
	console.log(`[Sign] message=${message}, wallet=`, wallet);

	try {
		let signature;
		let v: number;
		let web3;

		if (!wallet.isMetaMask && wallet.privateKey) {
			web3 = new Web3();
			// Sign using the provided private key
			const privateKey = wallet.privateKey;
			if (privateKey) {
				const signedData = web3.eth.accounts.sign(message, privateKey); // Sign the message, not the hash
				signature = signedData.signature;
			} else {
				throw new Error('Private key is undefined');
			}
		} else {
			eventCloud.emit('waitingMetaMaskConfirmation', {});

			const success = await requestAccountAccess();
			if (!success) {
				throw new Error('Account access not granted');
			}

			// Check if window.ethereum exists before proceeding
			if (window.ethereum) {
				try {
					await window.ethereum.request({
						method: 'eth_requestAccounts',
					});
					web3 = new Web3(window.ethereum);
				} catch (error) {
					console.error('User denied account access:', error);
					return null; // Exit function if user denied access
				}
			} else {
				console.error('Ethereum object not found.');
				return null;
			}

			// Sign using MetaMask (eth.personal.sign shows the human-readable message in MetaMask)
			try {
				signature = await web3.eth.personal.sign(
					message, // Signing the message directly
					wallet.address,
					'' // Pass an empty password for MetaMask
				);
				eventCloud.emit('unWaitMetaMask', {});
			} catch (signError: any) {
				eventCloud.emit('unWaitMetaMask', {});
				if (signError.code === 4001) {
					console.error('User denied message signature:', signError);
				} else {
					console.error('Error during signing:', signError);
				}
				return null; // Exit the function
			}
		}

		// Split the signature into r, s, and v
		const sig = signature.slice(2); // Remove the 0x prefix
		const r = `0x${sig.slice(0, 64)}`;
		const s = `0x${sig.slice(64, 128)}`;
		v = parseInt(`0x${sig.slice(128, 130)}`, 16);

		// Adjust v if necessary
		if (v < 27) v += 27;

		return { v, r, s };
	} catch (error) {
		console.error('Error signing message:', error);
		return null;
	}
};

export default SignMessage;

// src/pages/CharactersPage.tsx

import React, { useState, useEffect } from 'react';
import { eventCloud } from '../eventCloud';
import { Fighter } from '../store/types/fighterTypes';
import { Button } from '../components/Button/Button';
import classNames from 'classnames';
import { getBackendUrl } from '../helpers/getBackendUrl';
import { FighterPopover } from '../components/Fighter/FighterPopover';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as AttackIcon } from '../assets/icons/svg/attack.svg';
import { ReactComponent as SponsorCrown } from '../assets/icons/svg/sponsor-crown.svg';

const CharactersPage: React.FC = () => {
	const navigate = useNavigate();
	const [characters, setCharacters] = useState<Fighter[]>([]);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [selectedItem, setSelectedItem] = useState<Fighter | null>(null);

	useEffect(() => {
		const handleUserFightersUpdate = (fightersData: Fighter[] | null) => {
			console.log('userFightersStore', fightersData);

			if (!fightersData) return;
			setCharacters(fightersData);
		};

		eventCloud.on('user_fighters_update', handleUserFightersUpdate);

		return () => {
			eventCloud.off('user_fighters_update', handleUserFightersUpdate);
		};
	}, []);

	const handleFighterSelect = (fighterToSelect: Fighter | null) => {
		console.log('handleFighterSelect', fighterToSelect);
		eventCloud.emit('select_fighter', fighterToSelect);

		navigate('/profile');
	};

	return (
		<div className="h-full  text-xxs sm:text-xs overflow-x-auto">
			<FighterPopover
				anchorEl={anchorEl}
				fighter={selectedItem}
				onClose={() => {
					setSelectedItem(null);
					setAnchorEl(null);
				}}
			/>
			<div className="h-full">
				<div className="mt-4 text-xs">YOUR CHARACTERS</div>
				<div className="h-full flex flex-col mt-4">
					<table className="w-full table-fixed">
						<thead className="bg-gray-550 bg-opacity-50 text-gray-200 font-bold border-b border-gray-550 h-[27px]">
							<tr>
								<th className="text-left w-[60px] pl-2">#</th>
								<th className="text-left">NICKNAME</th>
								<th className="text-right w-[100px]">CLASS</th>
								<th className="text-right w-[100px]">HP</th>
								<th className="text-right w-[100px]">MP</th>
								<th className="text-right w-[100px]">DEF</th>
								<th className="text-right w-[100px]">ATK</th>
								<th className="text-right w-[100px]">SKILL</th>
								<th className="text-right w-[100px]">WIZ ATK</th>
								<th className="text-right w-[100px]">WIZ DMG</th>

								<th className="text-right w-[60px]">STR</th>
								<th className="text-right w-[60px]">AGI</th>
								<th className="text-right w-[60px]">INT</th>
								<th className="text-right w-[60px]">VIT</th>

								<th className="text-right w-[60px]">WIN</th>
								<th className="text-right w-[60px]">LOS</th>
								<th className="text-right w-[60px]">DRAW</th>
								<th className="text-right w-[80px]"></th>
							</tr>
						</thead>
					</table>

					{!characters ? (
						<div className="text-center text-gray-200 text-m m-10">Nothing to show</div>
					) : (
						<div className="flex-1">
							<table className="w-full table-fixed">
								<tbody className="text-white text-xs font-regular bg-gray-550">
									{characters
										.sort((a, b) => a.level - b.level)
										.map((item, index) => (
											<tr
												key={index}
												className={classNames(
													`border-b border-black ${item.level % 2 === 0 ? 'bg-gray-550' : 'bg-gray-200 bg-opacity-10'}`
												)}
											>
												<td className="text-left w-[60px] p-2 font-mono">{index + 1}</td>
												<td
													className="py-2 text-left font-bold cursor-pointer flex gap-x-1"
													onClick={(e: any) => {
														setSelectedItem(item);
														setAnchorEl(e.currentTarget);
													}}
												>
													{item.name} <span className="text-blue-550">[{item.level}]</span>
													{item.sponsor_expires &&
														new Date(item.sponsor_expires) > new Date() && (
															<SponsorCrown width={13} height={13} />
														)}
													<span
														className={classNames(
															`${item.health / item.max_health == 1 ? 'text-green-500' : 'text-red-550'}`
														)}
													>
														{' '}
														{Number((item.health / item.max_health) * 100).toFixed(0)}%
													</span>
													{item.in_combat && (
														<span className="text-yellow-500"> [IN COMBAT]</span>
													)}
													{item.id == eventCloud.selectedFighter?.id && (
														<span className="text-blue-550"> [SELECTED]</span>
													)}
													{item.available_stats > 0 && (
														<span className="text-purple-900">
															{' '}
															[{item.available_stats}]
														</span>
													)}
												</td>
												<td
													className={classNames(
														`text-right w-[100px] font-mono font-bold ${item.class == 0 ? 'text-yellow-500' : 'text-purple-900'}`
													)}
												>
													{item.class == 0 ? 'KNI' : 'WIZ'}
												</td>
												<td className="text-right w-[100px] font-mono font-bold">
													{item.max_health}
												</td>
												<td className="text-right w-[100px] font-mono font-bold">
													{item.max_mana.toFixed(0)}
												</td>

												<td className={classNames('text-right w-[100px] font-mono font-bold')}>
													{item.defence}
												</td>

												<td className="text-right w-[100px] font-mono font-bold">
													{item.min_damage} - {item.max_damage}
												</td>
												<td
													className={classNames(
														`p-2 text-right w-[100px] font-mono font-bold ${item?.skills?.length > 0 ? 'text-green-500' : 'text-red-550'}`
													)}
												>
													{item?.skills?.length > 0 ? 'YES' : 'NO'}
												</td>

												<td className="text-right w-[100px] font-mono font-bold">
													{item.min_wiz_damage} - {item.max_wiz_damage}
												</td>
												<td className="p-2 text-right w-[100px] font-mono font-bold">
													{item.wiz_damage}%
												</td>

												<td className="text-right w-[60px] font-mono">{item.strength}</td>
												<td className="text-right w-[60px] font-mono">{item.agility}</td>
												<td className="text-right w-[60px] font-mono">{item.intelligence}</td>
												<td className="p-2 text-right w-[60px] font-mono">{item.vitality}</td>

												<td className="text-right w-[60px] font-mono font-bold text-green-500">
													{item.victories}
												</td>
												<td className="text-right w-[60px] font-mono font-bold text-red-550">
													{item.defeats}
												</td>
												<td className="text-right w-[60px] font-mono font-bold text-yellow-500">
													{item.draws}
												</td>

												<td className="text-right w-[80px] ">
													<Button
														size="small"
														variant="blue-trans"
														onClick={() => {
															handleFighterSelect(item);
														}}
													>
														SELECT
													</Button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CharactersPage;

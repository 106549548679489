import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Wallets } from '../Wallets/Wallets';
import { Credits } from '../Credits/Credits';
import { FighterSelector } from '../Fighter/FighterSelector';
import { Wallet, Account, Fighter } from '../../store/types/fighterTypes';
import { HealthManaIndicator } from '../Fighter/HealthManaIndicator';

import { ReactComponent as IconLogo } from '../../assets/icons/svg/logo.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/svg/menu.svg';
import { ReactComponent as DonateIcon } from '../../assets/icons/svg/donate.svg';

interface HeaderProps {
	fighter: Fighter | null;
	account: Account | null;
	wallet: Wallet | null;
	setIsMenuOpen: any;
}

const Header: React.FC<HeaderProps> = ({ account, fighter, wallet, setIsMenuOpen }) => {
	return (
		<div className="flex flex-wrap gap-x-1.5 gap-y-1.5 justify-end">
			<div className="order-1 md:mt-0 flex justify-start flex-grow xl:!flex-grow-0 items-center">
				<NavLink to="/profile">
					<IconLogo className="w-[60px] xxxs:w-[80px]" />
				</NavLink>
			</div>
			<div className="order-2 flex-grow text-xs border-l border-gray-540 pl-2 items-center hidden xl:flex">
				<header className="flex flex-wrap items-center space-x-2">
					<NavLink className={({ isActive }) => (isActive ? 'text-blue-550' : '')} to="/profile">
						PROFILE
					</NavLink>
					<NavLink className={({ isActive }) => (isActive ? 'text-blue-550' : '')} to="/shop">
						SHOP
					</NavLink>
					{/*					<NavLink className={({ isActive }) => (isActive ? 'text-blue-550' : '')} to="/market">
						MARKETPLACE
					</NavLink>*/}
					<NavLink
						className={({ isActive }) =>
							isActive ? 'flex items-center text-blue-550' : 'flex items-center text-yellow-500'
						}
						to="/sponsor"
					>
						<DonateIcon className="mr-0.5" /> VIP
					</NavLink>
					{/*<NavLink
                  className={({ isActive }) =>
                    isActive ? "text-blue-550" : ""
                  }
                  to="/leaderboard"
                >
                  LEADERBOARD
                </NavLink>*/}
					<NavLink
						className={({ isActive }) => (isActive ? 'text-blue-550' : '')}
						to="https://docs.combats.game"
						target="_blank"
					>
						DOCS
					</NavLink>
				</header>
			</div>
			{fighter && (
				<div className="order-5 xxs:order-2 flex-grow max-w-[300px] min-w-[150px]">
					<HealthManaIndicator showRegen={true} fighter={fighter} />
				</div>
			)}

			<div className="order-6 xxs:order-3 min-w-[100px]  flex-grow max-w-[400px]">
				<FighterSelector />
			</div>
			<div className="order-2 xs:order-4 flex-grow max-w-[150px]">
				<Credits account={account} wallet={wallet} />
			</div>

			<div className="order-3 xs:order-5">
				<Wallets />
			</div>
			<div
				onClick={() => {
					setIsMenuOpen(true);
				}}
				className="relative active:top-px order-3 xxs:order-1 xl:hidden p-3 rounded-sm bg-gray-550"
			>
				<MenuIcon />
			</div>
		</div>
	);
};

export default Header;

import React from 'react';
import { ReactComponent as IconSpinner } from '../../assets/icons/svg/spinner.svg';

const LoadingSpinner: React.FC = () => (
	<div className="bg-black flex flex-col justify-center items-center h-full gap-y-4 text-blue-550">
		<div className="w-[100px]">
			<IconSpinner />
		</div>

		<p>Connecting to the server...</p>
	</div>
);

export default LoadingSpinner;

// src/pages/LandingPage.tsx
import React, { useEffect, useState } from 'react';
import { eventCloud } from '../eventCloud';
import CombatNarrow from '../components/Combat/CombatNarrow';
import CombatWide from '../components/Combat/CombatWide';
import { Account, Fighter } from '../store/types/fighterTypes';
import { getBackendUrl } from '../helpers/getBackendUrl';
import { useParams, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/Shared/LoadingSpinner';
import { HealthManaIndicator } from '../components/Fighter/HealthManaIndicator';
import { Button } from '../components/Button/Button';

type LandingPageProps = {
	fighter: Fighter | null;
};

const LandingPage: React.FC<LandingPageProps> = ({ fighter }) => {
	const navigate = useNavigate();

	// useEffect(() => {
	//     eventCloud.requestDemoFighter();
	// }, []);

	useEffect(() => {
		//if (!fighter) return;
		eventCloud.sendDemoFight();
	}, []);

	// const handleReturnToLobby = () => {
	// 	navigate('/profile'); // Navigate to /lobby when the button is clicked
	// };

	return (
		<div className="flex flex-col flex-grow h-full justify-center m-10 items-center">
			<div className="max-w-[800px] flex flex-col justify-center">
				<div className="text-center text-gray-200 mb-6">
					Once your health is fully restored, you will be automatically entered into the next available fight.
				</div>
				<HealthManaIndicator showRegen={true} fighter={fighter} />
				<div className="text-xs text-center text-white bg-gray-550 p-1 mt-4 rounded-sm">
					<a href="https://dmex.app" rel="noreferrer" target="_blank" className="hover:underline">
						<span className="text-purple-900 font-bold">AD:</span> Trade <b>crypto</b> futures with up to{' '}
						<span className="text-blue-550 font-bold">500x leverage</span> on{' '}
						<span className="text-yellow-500 font-bold">DMEX</span>
					</a>
				</div>
			</div>
		</div>
	);
};

export default LandingPage;

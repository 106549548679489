// src/components/Wallets/Wallets.tsx

import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { ethers } from 'ethers';
import classNames from 'classnames';
import { eventCloud } from '../../eventCloud';
import { Wallet, Account } from '../../store/types/fighterTypes';
import { formatNotificationMessage } from '../../helpers/notificationHelper';
import { toast } from 'react-toastify';
import { Button } from '../Button/Button';
import Modal from 'react-modal';

import Login from '../../components/Shared/Login';

import { SignMessage } from '../../helpers/signerMessage';

import { ReactComponent as CreditsIcon } from '../../assets/icons/svg/credits.svg';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/svg/down-arrow.svg';
import { ReactComponent as PrivateKeyIcon } from '../../assets/icons/svg/privatekey.svg';
import { ReactComponent as MetaMaskIcon } from '../../assets/icons/svg/metamask.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/svg/close.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/svg/copy.svg';

const toShortenedAddress = (a: string) => {
	return `${a.slice(0, 7)}…${a.slice(-8)}`;
};

const toShortenedAddress5 = (a: string) => {
	return `${a.slice(0, 5)}…${a.slice(-3)}`;
};

const toShortenedAddress3 = (a: string) => {
	return `${a.slice(0, 4)}…${a.slice(-2)}`;
};

export const Wallets = forwardRef<HTMLDivElement>((_, ref) => {
	const [wallets, setWallets] = useState<Wallet[]>([]);
	const [account, setAccount] = useState<Account | null>(null);
	const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
	const [walletForPrivateKey, setWalletForPrivateKey] = useState<Wallet | null>(null);
	const [privateKey, setPrivateKey] = useState('');
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [importPrivateKeyModalOpen, setImportPrivateKeyModalOpen] = useState(false);
	const [deleteWalletModalOpen, setDeleteWalletModalOpen] = useState(false);
	const [walletToDelete, setWalletToDelete] = useState<Wallet | null>(null);
	const selectedWalletRef = useRef<Wallet | null>(null);
	const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
	const [privateKeyModalOpen, setPrivateKeyModalOpen] = useState(false);

	useEffect(() => {
		const accountSub = eventCloud.accountStore.subscribe((account) => {
			if (!account) return;
			console.log('wallets account update', account, selectedWalletRef.current);

			if (selectedWalletRef.current?.address.toLowerCase() === account.address.toLowerCase()) {
				selectedWalletRef.current.session_id = account.session_id;
				selectedWalletRef.current.session_expires = account.session_expires;
				localStorage.setItem('selectedWallet', JSON.stringify(selectedWalletRef.current));
				setIsLoginModalOpen(false);
			}

			if (wallets.length > 0) {
				setWallets((prevWallets) =>
					prevWallets.map((wallet) =>
						wallet.address === account.address
							? {
									...wallet,
									session_id: account.session_id,
									session_expires: account.session_expires,
								}
							: wallet
					)
				);
				localStorage.setItem('wallets', JSON.stringify(wallets));
			}

			setAccount(account);
		});

		const handleLoginRequired = () => {
			console.log('login required', selectedWalletRef?.current);

			if (selectedWalletRef?.current) {
				handleSignAuth(selectedWalletRef?.current);
			}
		};

		eventCloud.on('login_required', handleLoginRequired);

		return () => {
			accountSub.unsubscribe();
			eventCloud.off('login_required', handleLoginRequired);
		};
	}, []);

	useEffect(() => {
		const storedWallets = localStorage.getItem('wallets');
		const storedSelectedWallet = localStorage.getItem('selectedWallet');

		if (storedWallets) {
			const walletsArray: Wallet[] = JSON.parse(storedWallets);
			setWallets(walletsArray);

			if (storedSelectedWallet) {
				const walletAddy: Wallet = JSON.parse(storedSelectedWallet);
				selectWallet(walletAddy);
			} else if (walletsArray.length > 0) {
				selectWallet(walletsArray[0]);
			}
		} else {
			generateNewWallet(true);
		}
	}, []);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setDropdownOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleSignAuth = async (wallet: Wallet) => {
		console.log('account handleSignAuth', wallet);

		if (!wallet) {
			console.error('Wallet is null');
			return;
		}

		if (wallet.isMetaMask) {
			setIsLoginModalOpen(true);
		}

		const timestamp = Math.floor(Date.now() / 1000);

		// Create a readable message to sign
		const readableMessage = `[COMBATS.GAME] Log in with wallet ${wallet.address}. Timestamp: ${timestamp}.`;

		const signatureResult = await SignMessage(readableMessage, wallet);

		if (!signatureResult) {
			console.error('Signature process was not completed.');
			return false;
		}

		const { v, r, s } = signatureResult;

		const referrer = localStorage.getItem('cg_afl') || '';

		// Call the eventCloud's requestWithdraw method
		eventCloud.sendAuthSigned(wallet.address, readableMessage, timestamp, referrer, v, r, s);
	};

	const selectWallet = (wallet: Wallet | null) => {
		if (selectedWalletRef.current?.address === wallet?.address) return;
		selectedWalletRef.current = wallet;
		setSelectedWallet(wallet);
		localStorage.setItem('selectedWallet', JSON.stringify(wallet));
		eventCloud.emit('wallet_selected', wallet);

		console.log('[selectWallet] ', wallet);
		if (wallet) {
			if (
				!wallet.session_id ||
				!wallet.session_expires ||
				new Date(wallet.session_expires).getTime() < Date.now()
			) {
				handleSignAuth(wallet);
			} else {
				console.log('[selectWallet] sendAuth ', wallet);
				eventCloud.sendAuth(wallet.address, wallet.session_id);
			}
		}
	};

	const deleteWallet = (address: string | undefined) => {
		if (!address) return;
		const updatedWallets = wallets.filter((wallet) => wallet.address !== address);
		setWallets(updatedWallets);
		localStorage.setItem('wallets', JSON.stringify(updatedWallets));

		if (selectedWallet && selectedWallet.address === address) {
			selectWallet(null);
			localStorage.removeItem('selectedWallet');
		}

		eventCloud.notify(
			formatNotificationMessage({
				title: 'Wallet Deleted',
				message: [
					{
						text: 'The wallet has been successfully deleted.',
						bold: false,
					},
				],
			}),
			'success',
			'500'
		);
	};

	const generateNewWallet = (forced: boolean) => {
		const newWallet = ethers.Wallet.createRandom();
		const walletData: Wallet = {
			address: newWallet.address.toLowerCase(),
			privateKey: newWallet.privateKey,
			isMetaMask: false,
		};
		const updatedWallets = [...wallets, walletData];
		setWallets(updatedWallets);
		localStorage.setItem('wallets', JSON.stringify(updatedWallets));
		selectWallet(walletData);

		if (!forced) {
			eventCloud.notify(
				formatNotificationMessage({
					title: 'Wallet Created',
					message: [
						{
							text: 'New wallet created, Backup private key in the wallet drop down',
							bold: false,
						},
					],
				}),
				'success',
				'1000'
			);

			// toast.success("New wallet generated successfully.", {
			// 	position: "bottom-right",
			// 	autoClose: 5000,
			// 	hideProgressBar: true,
			// 	closeOnClick: true,
			// 	pauseOnHover: true,
			// 	draggable: true,
			// 	progress: undefined,
			// 	theme: "dark",
			// });
		} else {
			// setTimeout(() => {
			// 	eventCloud.notify(
			// 		formatNotificationMessage({
			// 			title: 'Wallet Generated',
			// 			message: [
			// 				{
			// 					text: 'A fresh wallet was generated and stored in your browser.',
			// 					bold: false,
			// 				},
			// 			],
			// 		}),
			// 		'warn',
			// 		'500'
			// 	);
			// }, 1000);
		}
	};

	const handleImportPrivateKey = () => {
		console.log('[handleImportPrivateKey]');
		setImportPrivateKeyModalOpen(true);
		//importPrivateKey(privateKey);
	};

	const add0xPrefix = (key: string) => {
		if (!key.startsWith('0x')) {
			return `0x${key}`; // Add the '0x' prefix if missing
		}
		return key; // Return the key as is if it already has the '0x' prefix
	};

	const importPrivateKey = () => {
		try {
			// Trim whitespace and ensure the private key has the '0x' prefix
			const trimmedKey = privateKey.trim();
			const prefixedPrivateKey = add0xPrefix(trimmedKey);

			// Create a wallet instance from the private key
			const wallet = new ethers.Wallet(prefixedPrivateKey);

			// Get the wallet address in lowercase for consistency
			const walletAddress = wallet.address.toLowerCase();

			// Check if the wallet already exists in the wallets array
			const existingWallet = wallets.find((w) => w.address.toLowerCase() === walletAddress);

			if (existingWallet) {
				// Wallet already exists, select it
				selectWallet(existingWallet);
				toast.info('Wallet already exists. Selected the existing wallet.', {
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
				return;
			}

			// If wallet doesn't exist, add it
			const walletData: Wallet = {
				address: walletAddress,
				privateKey: prefixedPrivateKey,
				isMetaMask: false,
			};
			const updatedWallets = [...wallets, walletData];
			setWallets(updatedWallets);
			localStorage.setItem('wallets', JSON.stringify(updatedWallets));
			selectWallet(walletData);

			console.log('Wallet Address:', walletAddress, walletData);

			// Notify user of successful import
			eventCloud.notify(
				formatNotificationMessage({
					title: 'Wallet Imported',
					message: [
						{
							text: 'Wallet imported successfully.',
							bold: false,
						},
					],
				}),
				'success',
				'500'
			);

			// toast.success("Wallet imported successfully.", {
			// 	position: "bottom-right",
			// 	autoClose: 500,
			// 	hideProgressBar: true,
			// 	closeOnClick: true,
			// 	pauseOnHover: true,
			// 	draggable: true,
			// 	progress: undefined,
			// 	theme: "dark",
			// });
		} catch (error) {
			console.error('Error extracting wallet address:', error);
			// Handle any errors (e.g., invalid private key format)
			eventCloud.notify(
				formatNotificationMessage({
					title: 'Import Error',
					message: [
						{
							text: 'Invalid private key.',
							bold: false,
						},
					],
				}),
				'error',
				'1000'
			);
			// toast.error(
			// 	"Failed to import wallet. Please check the private key and try again.",
			// 	{
			// 		position: "bottom-right",
			// 		autoClose: 1500,
			// 		hideProgressBar: true,
			// 		closeOnClick: true,
			// 		pauseOnHover: true,
			// 		draggable: true,
			// 		progress: undefined,
			// 		theme: "dark",
			// 	},
			// );
		}
	};

	const connectMetaMask = async () => {
		if (window.ethereum) {
			try {
				const accounts: string[] = await window.ethereum.request({
					method: 'eth_requestAccounts',
				});
				const account = accounts[0].toLowerCase(); // Convert account to lowercase

				// Get wallets from localStorage or state, using the Wallet type
				const currentWallets: Wallet[] =
					wallets.length > 0 ? wallets : JSON.parse(localStorage.getItem('wallets') || '[]');

				// Check if the wallet is already in the list
				const existingWallet = currentWallets.find((w: Wallet) => w.address.toLowerCase() === account);

				if (existingWallet) {
					selectWallet(existingWallet);
				} else {
					const walletData: Wallet = {
						address: account,
						privateKey: '', // MetaMask does not expose private keys
						isMetaMask: true,
					};

					// Update state and localStorage only if the wallet is not already added
					const updatedWallets = [...currentWallets, walletData];
					setWallets(updatedWallets);
					localStorage.setItem('wallets', JSON.stringify(updatedWallets));

					selectWallet(walletData);

					eventCloud.notify(
						formatNotificationMessage({
							title: 'MetaMask connected',
							message: [
								{
									text: 'Connected to MetaMask wallet: ' + account,
									bold: false,
								},
							],
						}),
						'success',
						'500'
					);

					// toast.success("MetaMask connected successfully.", {
					// 	position: "bottom-right",
					// 	autoClose: 5000,
					// 	hideProgressBar: true,
					// 	closeOnClick: true,
					// 	pauseOnHover: true,
					// 	draggable: true,
					// 	progress: undefined,
					// 	theme: "dark",
					// });
				}
			} catch (error) {
				eventCloud.notify(
					formatNotificationMessage({
						title: 'MetaMask error',
						message: [
							{
								text: 'Error connecting to MetaMask: ' + error,
								bold: false,
							},
						],
					}),
					'error',
					'500'
				);
				console.error('Error connecting to MetaMask', error);

				// toast.error(
				// 	"Failed to connect to MetaMask. Please try again.",
				// 	{
				// 		position: "bottom-right",
				// 		autoClose: 500,
				// 		hideProgressBar: true,
				// 		closeOnClick: true,
				// 		pauseOnHover: true,
				// 		draggable: true,
				// 		progress: undefined,
				// 		theme: "dark",
				// 	},
				// );
			}
		} else {
			eventCloud.notify(
				formatNotificationMessage({
					title: 'MetaMask not found',
					message: [
						{
							text: 'Make sure the MetaMask extension is installed',
							bold: false,
						},
					],
				}),
				'error',
				'500'
			);
			console.log('MetaMask is not installed!');

			// toast.error(
			// 	"MetaMask is not installed. Please install it to proceed.",
			// 	{
			// 		position: "bottom-right",
			// 		autoClose: 500,
			// 		hideProgressBar: true,
			// 		closeOnClick: true,
			// 		pauseOnHover: true,
			// 		draggable: true,
			// 		progress: undefined,
			// 		theme: "dark",
			// 	},
			// );
		}
	};

	const handleCopy = (text: string) => {
		navigator.clipboard.writeText(text);
	};

	const remove0xPrefix = (key: string | undefined): string => {
		if (!key) return '';
		if (key.startsWith('0x')) {
			return key.slice(2); // Remove the first two characters if the key starts with '0x'
		}
		return key; // Return the key as is if it doesn't start with '0x'
	};

	return (
		<div className="w-full flex justify-end space-x-2">
			<div className="relative w-full inline-block text-left flex-none " ref={dropdownRef}>
				<div
					className="relative active:top-px bg-gray-550 py-2 px-1 rounded-sm cursor-pointer select-none"
					onClick={() => setDropdownOpen(!dropdownOpen)}
				>
					<div className="flex items-center">
						<div className="hidden xxxs:block">
							{selectedWallet?.isMetaMask ? (
								<MetaMaskIcon width={18} height={18} />
							) : (
								<PrivateKeyIcon width={18} height={18} />
							)}
						</div>

						<div className="hidden xxxs:block">
							<div className="ml-2 text-xs">
								{selectedWallet ? toShortenedAddress5(selectedWallet.address) : 'None'}
							</div>
						</div>
						<div className="xxxs:hidden">
							<div className="ml-2 text-xs">
								{selectedWallet ? toShortenedAddress3(selectedWallet.address) : 'None'}
							</div>
						</div>
						<div>
							<DownArrowIcon
								className={classNames('ml-2', {
									'rotate-180': dropdownOpen,
								})}
								width={8}
								height={8}
							/>
						</div>
					</div>
				</div>

				{/* Dropdown Panel */}
				{dropdownOpen && (
					<div
						className="fixed flex flex-col bottom-0 top-0 h-full origin-top-right  p-4 right-0 sm:mt-2 w-80 rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 z-10 bg-black text-white border border-gray-550"
						role="menu"
						aria-orientation="vertical"
						aria-labelledby="options-menu"
					>
						<div className="text-m flex justify-between items-center">
							<div>Wallets</div>
							<div>
								<button onClick={() => setDropdownOpen(false)}>
									<CloseIcon width={14} height={14} />
								</button>
							</div>
						</div>
						<div className="py-4 flex  flex-col flex-grow justify-between" role="none">
							<div className="max-h-full overflow-y-auto">
								{wallets.length > 0 ? (
									wallets.map((wallet, index) => (
										<div
											key={index}
											className={classNames(
												'p-2 mb-2 rounded-sm cursor-pointer select-none  relative active:top-px',
												{
													'bg-gray-550': selectedWallet?.address != wallet.address,
												},
												{
													'bg-blue-550 bg-opacity-20 ring-1 ring-inset ring-blue-550':
														selectedWallet?.address == wallet.address,
												}
											)}
										>
											<div
												onClick={() => {
													selectWallet(wallet);
													setDropdownOpen(false);
												}}
												className="flex"
											>
												<div className="flex-none mt-2.5">
													{wallet?.isMetaMask ? (
														<MetaMaskIcon width={18} height={18} />
													) : (
														<PrivateKeyIcon width={18} height={18} />
													)}
												</div>
												<div className="flex-grow">
													<div className="flex flex-grow items-center">
														<div
															className="text-sm cursor-pointer rounded p-2 font-regular text-base flex-grow flex items-center"
															role="menuitem"
														>
															<div className="flex justify-between items-center text-m">
																<span>{toShortenedAddress(wallet.address)}</span>
															</div>
															<div
																onClick={(e) => {
																	e.stopPropagation();
																	handleCopy(wallet.address);
																}}
																className="ml-1 z-100 relative active:top-1"
															>
																<CopyIcon width={15} height={15} />
															</div>
														</div>
														<div>
															{selectedWallet?.address == wallet.address && (
																<div className="bg-blue-550 rounded-sm text-black text-xs p-0.5 font-bold">
																	Current
																</div>
															)}
														</div>
													</div>
													<div className="flex ml-2 mb-2">
														<div className="flex space-x-2">
															{!wallet.isMetaMask && (
																<Button
																	size="small"
																	variant="blue-trans"
																	onClick={() => {
																		setWalletForPrivateKey(wallet);
																		setPrivateKeyModalOpen(true);
																	}}
																>
																	Private Key
																</Button>
															)}
															<Button
																size="small"
																variant="red-trans"
																onClick={() => {
																	setWalletToDelete(wallet);

																	if (!wallet.isMetaMask) {
																		setDeleteWalletModalOpen(true);
																	} else {
																		deleteWallet(wallet.address);
																	}
																}}
															>
																Delete
															</Button>
														</div>
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<div className="px-4 py-2 text-sm text-gray-500">
										No wallets available. Generate or import a wallet.
									</div>
								)}
							</div>

							<div className="space-y-2">
								<div>
									<Button
										variant="black"
										size="large"
										onClick={() => {
											generateNewWallet(false);
											setDropdownOpen(false);
										}}
									>
										GENERATE NEW WALLET
									</Button>
								</div>
								<div>
									<Button
										size="large"
										onClick={() => {
											connectMetaMask();
											setDropdownOpen(false);
										}}
									>
										CONNECT METAMASK
									</Button>
								</div>
								<div>
									{/*<input
                    type="text"
                    name="private_key"
                    value={privateKey}
                    onChange={(e) => setPrivateKey(e.target.value)}
                    placeholder="Enter Private Key"
                    className="mr-2 px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  />*/}
									<Button
										variant="white"
										size="large"
										onClick={() => {
											handleImportPrivateKey();
											setDropdownOpen(false);
										}}
									>
										IMPORT WALLET
									</Button>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>

			{/* Modal for importing wallet */}
			<Modal
				isOpen={importPrivateKeyModalOpen}
				onRequestClose={() => {
					setImportPrivateKeyModalOpen(false);
					setPrivateKey('');
				}}
				contentLabel="Enter Private Key"
				className="w-full fixed bottom-0 sm:bottom-auto sm:w-[400px] max-w-md mx-auto mt-20 p-4 bg-black ring-1 ring-gray-540"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div className="flex items-center mb-8">
					<div className="flex-grow">
						<span className="font-regular text-m mb-4">Import Wallet</span>
					</div>
					<div
						onClick={() => {
							setImportPrivateKeyModalOpen(false);
							setPrivateKey('');
						}}
						className="cursor-pointer"
					>
						<CloseIcon width={18} height={18} />
					</div>
				</div>
				<div>
					<div className="text-bold text-xxs text-gray-200 mb-2">Private Key</div>
					<input
						type="text"
						value={privateKey}
						onChange={(e) => setPrivateKey(e.target.value)}
						placeholder="Enter private key"
						className="w-full px-3 py-2 rounded-sm text-base placeholder-gray-200 bg-gray-550 font-regular"
					/>
					<div className="flex justify-end space-x-2 mt-8">
						<Button
							variant="black"
							size="large"
							onClick={() => {
								setImportPrivateKeyModalOpen(false);
								importPrivateKey();
								setPrivateKey('');
							}}
						>
							CONFIRM
						</Button>
					</div>
				</div>
			</Modal>

			{/* Modal for deleting wallet */}
			<Modal
				isOpen={deleteWalletModalOpen}
				onRequestClose={() => {
					setDeleteWalletModalOpen(false);
				}}
				contentLabel="Delete Wallet"
				className="fixed bottom-0 w-full sm:bottom-auto sm:w-[400px] max-w-md mx-auto mt-20 p-4 bg-black ring-1 ring-gray-540 w-[480px]"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div className="flex items-center mb-8">
					<div className="flex-grow">
						<span className="font-regular text-m mb-4">Delete Wallet</span>
					</div>
					<div
						onClick={() => {
							setDeleteWalletModalOpen(false);
						}}
						className="cursor-pointer"
					>
						<CloseIcon width={18} height={18} />
					</div>
				</div>
				<div>
					<div className="text-sm text-gray-200 mb-2">
						Are you sure you want to delete wallet
						<br />
						<span className="font-bold text-white text-xxs whitespace-wrap break-all">
							{walletToDelete?.address}
						</span>
						<div className="text-red-550 mt-4">
							Make sure you have backed up your private key. If you lose your private key you will not be
							able to access your account
						</div>
					</div>
					<div className="flex justify-end space-x-2 mt-8">
						<Button
							variant="black"
							size="large"
							onClick={() => {
								deleteWallet(walletToDelete?.address);
								setDeleteWalletModalOpen(false);
							}}
						>
							CONFIRM
						</Button>
					</div>
				</div>
			</Modal>

			<Modal
				isOpen={isLoginModalOpen}
				contentLabel="Login"
				className="w-[400px] max-w-md mx-auto mt-20 p-4 bg-black ring-1 ring-gray-540 outline-none"
				overlayClassName="fixed inset-0 bg-black bg-opacity-100 flex justify-center items-center"
			>
				<Login />
			</Modal>

			<Modal
				isOpen={privateKeyModalOpen}
				onRequestClose={() => {
					setPrivateKeyModalOpen(false);
				}}
				contentLabel="Delete Wallet"
				className="fixed bottom-0 w-full sm:bottom-auto sm:w-[400px] max-w-md mx-auto mt-20 p-4 bg-black ring-1 ring-gray-540 w-[480px]"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div className="flex items-center mb-8">
					<div className="flex-grow">
						<span className="font-regular text-m mb-4">Backup Private Key</span>
					</div>
					<div
						onClick={() => {
							setPrivateKeyModalOpen(false);
						}}
						className="cursor-pointer"
					>
						<CloseIcon width={18} height={18} />
					</div>
				</div>
				<div>
					<div className="text-xs text-gray-200 mb-2">
						This is your wallet Private Key. Make sure you save it in a safe place. If you lose it, you will
						not be able to access your account.
						<br />
						<div className="text-sm mt-4 text-white">Wallet Address</div>
						<span className="font-bold text-blue-550 whitespace-wrap break-all">
							{walletForPrivateKey?.address}
						</span>
						<br />
						<div className="mt-6 text-white font-bold flex mb-2">
							PRIVATE KEY
							<span
								className="text-gray-550 ml-1 active:top-px relative cursor-pointer select-none"
								onClick={() => {
									navigator.clipboard.writeText(remove0xPrefix(walletForPrivateKey?.privateKey));
								}}
							>
								<CopyIcon />
							</span>
						</div>
						<div className="text-red-550 whitespace-wrap break-all">
							{remove0xPrefix(walletForPrivateKey?.privateKey)}
						</div>
					</div>
					<div className="flex justify-end space-x-2 mt-8">
						<Button
							variant="black"
							size="large"
							onClick={() => {
								setPrivateKeyModalOpen(false);
							}}
						>
							CLOSE
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
});

Wallets.displayName = 'Wallets';

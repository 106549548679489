import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { Fighter } from '../../store/types/fighterTypes';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { Tooltip } from 'react-tooltip';
import { eventCloud } from '../../eventCloud';
import { getRegenMultiplier, getNextRegenPrice } from '../../helpers/regenValues';

import { ReactComponent as HealthIcon } from '../../assets/icons/svg/health.svg';
import { ReactComponent as ManaIcon } from '../../assets/icons/svg/mana.svg';
import { ReactComponent as FastForwardIcon } from '../../assets/icons/svg/fast-forward.svg';

type HealthManaIndicatorProps = {
	fighter: Fighter | null;
	showRegen?: boolean;
	showManaNumber?: boolean;
	showHealthNumber?: boolean;
};

export const HealthManaIndicator = forwardRef<HTMLDivElement, HealthManaIndicatorProps>(
	({ fighter, showRegen, showManaNumber = true, showHealthNumber = true, ...rest }, ref) => {
		const handleIncreaseRegenLevel = () => {
			eventCloud.increaseRegenLevel();
		};

		return (
			<div className="flex space-x-1 select-none h-full items-center">
				<div className="w-full flex flex-col">
					<div className="flex justify-between text-xxxs lg:text-xs items-center h-full">
						{showHealthNumber && (
							<div className="flex items-center text-red-550">
								<HealthIcon width={12} height={12} className="mr-0.5" />
								<span className="text-red-550 font-bold">{Math.floor(Number(fighter?.health))}</span>
								<span className=" pt-0.5 text-gray-200">
									/{Math.floor(Number(fighter?.max_health))}
								</span>
							</div>
						)}

						{showManaNumber && (
							<div className="flex space-x-1 items-center">
								<div className="flex items-center">
									<ManaIcon width={12} height={12} />
									<span className="text-blue-550 font-bold">{Math.floor(Number(fighter?.mana))}</span>
									<span className=" pt-0.5 text-gray-200">
										/{Math.floor(Number(fighter?.max_mana))}
									</span>
								</div>
							</div>
						)}
					</div>
					<div>
						<ProgressBar
							progress={(Math.floor(Number(fighter?.health)) / Number(fighter?.max_health)) * 100}
							height="h-1.5"
							color="bg-red-550"
							bgColor="bg-gray-550"
						/>
					</div>
					<div className="pt-0.5">
						<ProgressBar
							progress={(Number(fighter?.mana) / Number(fighter?.max_mana)) * 100}
							height="h-0.5"
							color="bg-blue-550"
							bgColor="bg-gray-550"
						/>
					</div>
				</div>
				{fighter && showRegen && (
					<>
						<div
							className="w-[50px] relative active:top-px select-none cursor-pointer bg-gray-550 rounded-sm px-1 text-xs flex flex-col pt-1 items-center px-2.5"
							data-tooltip-id="fast-forawrd-button"
							onClick={handleIncreaseRegenLevel}
						>
							<div className="">
								<FastForwardIcon width={12} height={12} />
							</div>
							<div className="font-bold text-sm">{getRegenMultiplier(fighter.regen_level | 0) + 'x'}</div>
						</div>
						<Tooltip id="fast-forawrd-button" className="z-10" style={{ fontSize: '10px' }} place="bottom">
							<div className="text-xs">
								<div className="font-bold text-center">Increase Hp/Mana Regen Speed</div>
								<div
									className={classNames(
										`text-center ${fighter.regen_level == 0 && 'bg-blue-550 bg-opacity-25 text-white'}`
									)}
								>
									<b>1x</b> Price: <span className="font-bold text-blue-550">0 CR</span>
								</div>
								<div
									className={classNames(
										`text-center ${fighter.regen_level == 1 && 'bg-white text-black'}`
									)}
								>
									<b>2x</b> Price: <span className="font-bold text-blue-550">100 CR</span>
								</div>
								<div
									className={classNames(
										`text-center ${fighter.regen_level == 2 && 'bg-white text-black'}`
									)}
								>
									<b>4x</b> Price: <span className="font-bold text-blue-550">200 CR</span>
								</div>
								<div
									className={classNames(
										`text-center ${fighter.regen_level == 3 && 'bg-white text-black'}`
									)}
								>
									<b>8x</b> Price: <span className="font-bold text-blue-550">500 CR</span>
								</div>
								<div
									className={classNames(
										`text-center ${fighter.regen_level == 4 && 'bg-white text-black'}`
									)}
								>
									<b>16x</b> Price: <span className="font-bold text-blue-550">1K CR</span>
								</div>
								<div
									className={classNames(
										`text-center ${fighter.regen_level == 5 && 'bg-white text-black'}`
									)}
								>
									<b>32x</b> Price: <span className="font-bold text-blue-550">5K CR</span>
								</div>
								<div
									className={classNames(
										`text-center ${fighter.regen_level == 6 && 'bg-white text-black'}`
									)}
								>
									<b>64x</b> Price: <span className="font-bold text-blue-550">10K CR</span>
								</div>

								<div className={classNames(`text-center mt-2`)}>
									Prices are for each regen speed increase <br />
									(not cummulative)
								</div>
							</div>
						</Tooltip>
					</>
				)}
			</div>
		);
	}
);

HealthManaIndicator.displayName = 'HealthManaIndicator';

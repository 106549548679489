// src/components/EventListener.tsx
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { eventCloud } from '../eventCloud';
import { toast } from 'react-toastify';
import { Combat } from '../store/types/fighterTypes';

const EventListener: React.FC = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const handleCombatUpdate = (combat: Combat) => {
			console.log('[handleCombatUpdate] ', combat);
			//if (combat.active_round == null) return;
			if (
				!combat.taker_id ||
				(combat.maker_id != eventCloud.selectedFighter?.id && combat.taker_id != eventCloud.selectedFighter?.id)
			)
				return;
			// Redirect to the combat page

			console.log('navigate', combat.id);
			navigate(`/combat/${combat.id}`);
		};

		// Attach the event listener
		eventCloud.on('combat_update', handleCombatUpdate);

		// Cleanup the event listener on unmount
		return () => {
			eventCloud.off('combat_update', handleCombatUpdate);
		};
	}, [navigate]);

	useEffect(() => {
		const combatsSub = eventCloud.activeCombatsStore.subscribe((newCombats) => {
			for (const combat in newCombats) {
				if (
					(newCombats[combat].maker_id == eventCloud.selectedFighter?.id ||
						newCombats[combat].taker_id == eventCloud.selectedFighter?.id) &&
					!newCombats[combat].closed &&
					newCombats[combat].taker
				) {
					console.warn('eventlistener navigate', combat);
					navigate(`/combat/${combat}`);
				}
			}
		});

		// Cleanup subscription on unmount
		return () => {
			combatsSub.unsubscribe();
		};
	}, []);

	return null; // This component doesn't render anything
};

export default EventListener;

import { Combat } from '../types/fighterTypes';

// Action types
const ADD_ACTIVE_COMBAT = 'ADD_ACTIVE_COMBAT';
const REMOVE_ACTIVE_COMBAT = 'REMOVE_ACTIVE_COMBAT';
const ADD_FINISHED_COMBAT = 'ADD_FINISHED_COMBAT';
const SET_ACTIVE_COMBATS = 'SET_ACTIVE_COMBATS';
const SET_FINISHED_COMBATS = 'SET_FINISHED_COMBATS';

// Action creators
export const addActiveCombat = (combat: Combat) => ({
	type: ADD_ACTIVE_COMBAT,
	payload: combat,
});

export const removeActiveCombat = (combatId: string) => ({
	type: REMOVE_ACTIVE_COMBAT,
	payload: combatId,
});

export const addFinishedCombat = (combat: Combat) => ({
	type: ADD_FINISHED_COMBAT,
	payload: combat,
});

// Initial state
const initialState = {
	activeCombats: {} as Record<string, Combat>,
	finishedCombats: [] as Combat[],
};

export const setActiveCombats = (combats: Record<string, Combat>) => ({
	type: SET_ACTIVE_COMBATS,
	payload: combats,
});

export const setFinishedCombats = (combats: Combat[]) => ({
	type: SET_FINISHED_COMBATS,
	payload: combats,
});

// Reducer
const combatReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case ADD_ACTIVE_COMBAT:
			return {
				...state,
				activeCombats: {
					...state.activeCombats,
					[action.payload.id]: action.payload,
				},
			};

		case REMOVE_ACTIVE_COMBAT: {
			const { [action.payload]: _, ...remainingCombats } = state.activeCombats;
			return {
				...state,
				activeCombats: remainingCombats,
			};
		}

		case ADD_FINISHED_COMBAT:
			return {
				...state,
				finishedCombats: [...state.finishedCombats, action.payload],
			};

		case SET_ACTIVE_COMBATS:
			return {
				...state,
				activeCombats: action.payload,
			};

		case SET_FINISHED_COMBATS:
			return {
				...state,
				finishedCombats: action.payload,
			};

		default:
			return state;
	}
};

export default combatReducer;

// Shop.tsx

import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { Item, Fighter } from '../../store/types/fighterTypes';
import { eventCloud } from '../../eventCloud';
import { getBackendUrl } from '../../helpers/getBackendUrl';
import { getClassName } from '../../helpers/getClassName';
import { Tabs } from '../Shared/Tabs';
import { ItemThumbnail } from '../Item/ItemThumbnail';
import { ItemPopover } from '../Item/ItemPopover';
import { Pagination } from '../Pagination/Pagination';
import { Button } from '../Button/Button';
import { MarketRow } from '../Market/MarketRow';
import { MarketHistoryRow } from '../Market/MarketHistoryRow';
import classNames from 'classnames';
import Popover from '@mui/material/Popover';
import Modal from 'react-modal';

import { ReactComponent as CreditsIcon } from '../../assets/icons/svg/credits-blue.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/svg/close.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/svg/down-arrow.svg';

type ShopProps = {
	fighter?: Fighter | null;
};

export const Shop = forwardRef<HTMLDivElement, ShopProps>(({ fighter, ...rest }, ref) => {
	const [shopItems, setShopItems] = useState<Item[] | null>(null);
	const [totalPages, setTotalPages] = useState(0);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [classAnchorEl, setClassAnchorEl] = useState<HTMLElement | null>(null);
	const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
	const [sortAnchorEl, setSortAnchorEl] = useState<HTMLElement | null>(null);

	const [selectedItem, setSelectedItem] = useState<Item | null>(null);
	const [currentPage, setCurrentPage] = useState(1);

	const [itemToBuy, setItemToBuy] = useState<Item | null>(null);
	const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);

	const [filterClass, setFilterClass] = useState<number | null>(null);
	const [filterCategory, setFilterCategory] = useState<string | null>(null);
	const [sortCriteria, setSortCriteria] = useState<string | null>('Price Lowest');

	useEffect(() => {
		fetchShop();
	}, []);

	const fetchShop = () => {
		fetch(getBackendUrl() + '/shop')
			.then((response) => {
				if (!response.ok) {
					throw new Error('Failed to fetch market items');
				}
				return response.json();
			})
			.then((data: any) => {
				//console.warn("shopItems", shopItems);
				setShopItems(data.items);
			})
			.catch((error) => {
				console.error('Error fetching market items:', error);
			});
	};

	const handleBuyShopItem = (item: Item) => {
		setItemToBuy(item);
		setIsBuyModalOpen(true);
	};

	const handleDoBuyShopItem = (id: string | undefined) => {
		if (!id) return;
		console.log('[handleBuyMarketItem] ', id);
		eventCloud.buyShopItem(id);
	};

	return (
		<div ref={ref} className="w-full flex-grow">
			<Modal
				isOpen={isBuyModalOpen}
				onRequestClose={() => {
					setIsBuyModalOpen(false);
				}}
				contentLabel="List item on the market"
				className="text-xxs sm:text-xs fixed bottom-0 sm:bottom-auto w-full sm:w-[400px] mx-auto mt-20 p-4 bg-black ring-1 ring-gray-540"
				overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
			>
				<div className="flex items-center mb-4">
					<div className="flex-grow">
						<span className="font-regular mb-4">CONFIRM ITEM PURCHASE</span>
					</div>
					<div
						onClick={() => {
							setIsBuyModalOpen(false);
						}}
						className="cursor-pointer"
					>
						<CloseIcon width={18} height={18} />
					</div>
				</div>
				<div className="flex flex-col gap-y-2">
					<div
						className={classNames(
							`flex justify-center font-bold text-xxxs sm:text-m ${(itemToBuy?.has_luck || itemToBuy?.has_skill) && 'text-blue-550'}`
						)}
					>
						{itemToBuy?.name}
						{itemToBuy?.level_upgradeable && ` +${itemToBuy?.level}`}
						{itemToBuy?.has_skill && ` +Skill`}
						{itemToBuy?.has_luck && ` +Luck`}
					</div>
					<div className=" flex justify-center">
						<div className="w-1/2">
							<ItemThumbnail item={itemToBuy ? itemToBuy : null} />
						</div>
					</div>
					<div className="flex justify-center items-center">
						<CreditsIcon className="h-[8px] sm:h-[9px] md:h-[10px] lg:h-[12px]" />
						<div className="font-bold text-blue-550">
							{itemToBuy && formatNumLocale(itemToBuy?.price * 10, 0)}
						</div>
					</div>
				</div>
				<div>
					<div className="mt-8">
						<Button
							variant="basic"
							size="large"
							onClick={() => {
								setIsBuyModalOpen(false);
								handleDoBuyShopItem(itemToBuy?.name);
							}}
						>
							CONFIRM
						</Button>
					</div>
				</div>
			</Modal>
			<Popover
				open={Boolean(classAnchorEl)}
				anchorEl={classAnchorEl}
				onClose={() => {
					setClassAnchorEl(null);
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				sx={{
					'& .MuiPaper-root': {
						borderRadius: 0,
					},
				}}
			>
				<div className="text-xs bg-black text-white p-2 flex flex-col gap-y-1 cursor-pointer select-none">
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterClass(null);
							setClassAnchorEl(null);
						}}
					>
						All
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterClass(0);
							setClassAnchorEl(null);
						}}
					>
						Knight
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterClass(1);
							setClassAnchorEl(null);
						}}
					>
						Wizard
					</div>
				</div>
			</Popover>

			<Popover
				open={Boolean(categoryAnchorEl)}
				anchorEl={categoryAnchorEl}
				onClose={() => {
					setCategoryAnchorEl(null);
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				sx={{
					'& .MuiPaper-root': {
						borderRadius: 0,
					},
				}}
			>
				<div className="text-xs bg-black text-white p-2 flex flex-col gap-y-1 cursor-pointer select-none">
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterCategory(null);
							setCategoryAnchorEl(null);
						}}
					>
						All
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterCategory('Armor');
							setCategoryAnchorEl(null);
						}}
					>
						Armor
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterCategory('Weapon');
							setCategoryAnchorEl(null);
						}}
					>
						Weapon
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setFilterCategory('Wearable');
							setCategoryAnchorEl(null);
						}}
					>
						Wearable
					</div>
				</div>
			</Popover>

			<Popover
				open={Boolean(sortAnchorEl)}
				anchorEl={sortAnchorEl}
				onClose={() => {
					setSortAnchorEl(null);
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				sx={{
					'& .MuiPaper-root': {
						borderRadius: 0,
					},
				}}
			>
				<div className="text-xs bg-black text-white p-2 flex flex-col gap-y-1 cursor-pointer select-none">
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setSortCriteria('Price Highest');
							setSortAnchorEl(null);
						}}
					>
						Price Highest
					</div>
					<div
						className="hover:bg-gray-550"
						onClick={() => {
							setSortCriteria('Price Lowest');
							setSortAnchorEl(null);
						}}
					>
						Price Lowest
					</div>
				</div>
			</Popover>

			<div className="text-m w-full overflow-x-auto">
				<div className="text-xxs font-bold mb-2 flex justify-between">
					<div className="flex gap-x-2">
						<div className=" text-gray-200">Filter:</div>
						<div
							className="relative flex gap-x-1 items-center select-none cursor-pointer active:top-px"
							onClick={(e: any) => {
								setClassAnchorEl(e.currentTarget);
							}}
						>
							<div>{filterClass == null ? 'Class' : filterClass == 0 ? 'Knight' : 'Wizard'}</div>
							<div>
								<ArrowIcon />
							</div>
						</div>
						<div
							className="relative flex gap-x-1 items-center select-none cursor-pointer active:top-px"
							onClick={(e: any) => {
								setCategoryAnchorEl(e.currentTarget);
							}}
						>
							<div>{filterCategory == null ? 'Category' : filterCategory}</div>
							<div>
								<ArrowIcon />
							</div>
						</div>
					</div>

					<div className="flex gap-x-2">
						<div className=" text-gray-200">Sort By:</div>
						<div
							className="relative flex gap-x-1 items-center select-none cursor-pointer active:top-px"
							onClick={(e: any) => {
								setSortAnchorEl(e.currentTarget);
							}}
						>
							<div>{sortCriteria}</div>
							<div>
								<ArrowIcon />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-wrap gap-0.5 text-xxxs sm:text-xxs md:text-xs lg:text-sm">
				{shopItems
					?.filter((item) => (filterClass ? item.class.includes(Number(filterClass)) : true))
					.filter((item) => (filterCategory ? item.category === filterCategory : true))
					.sort((a, b) => {
						if (sortCriteria === 'Price Lowest') {
							return a.price - b.price;
						} else if (sortCriteria === 'Price Highest') {
							return b.price - a.price;
						}
						return 0;
					})
					.map((item, index) => (
						<div
							key={index}
							className="w-[calc(50%-0.1rem)] sm:w-[calc(33%-0.1rem)] md:w-[calc(25%-0.1rem)] lg:w-[calc(20%-0.1rem)] xl:w-[calc(16.6%-0.1rem)] p-2 bg-gray-550 bg-opacity-50 rounded-sm"
						>
							<div className="flex flex-col gap-y-2">
								<div className="flex gap-x-2 items-center">
									<div className="max-w-[50px] sm:max-w-[80px] xl:max-w-[100px]">
										<ItemThumbnail
											item={item}
											onClick={(e: any) => {
												setAnchorEl(e.currentTarget);
												setSelectedItem(item);
											}}
										/>
									</div>

									<div className="flex flex-col gap-y-2">
										<div className="font-bold">{item.name}</div>
										<div className="text-blue-550">{item.category}</div>
									</div>
								</div>
								<div className="flex justify-between">
									<div className="text-gray-200">Class</div>
									<div className="text-white">
										{item.class &&
											item.class
												.map(
													(_, index, array) =>
														getClassName(item.class[index]) +
														(index < array.length - 1 ? ', ' : '')
												)
												.join('')}
									</div>
								</div>
								<div className="flex justify-between">
									<div className="text-gray-200">Price</div>
									<div className="text-blue-550 font-bold flex items-center">
										<CreditsIcon className="h-[8px] sm:h-[9px] md:h-[10px] lg:h-[12px]" />
										{formatNumLocale(item.price * 10, 0)}
									</div>
								</div>

								<div>
									<Button
										variant="basic"
										size="small"
										className="p-1 xxxs:p-2 sm:p-2 text-xxs sm:text-xs lg:text-sm xl:text-base"
										onClick={() => {
											setIsBuyModalOpen(true);
											setItemToBuy(item);
										}}
									>
										BUY
									</Button>
								</div>
							</div>
						</div>
					))}
			</div>

			<ItemPopover
				anchor="center"
				selectedItem={selectedItem}
				anchorEl={anchorEl}
				fighter={fighter}
				onClose={() => {
					setAnchorEl(null);
					setSelectedItem(null);
				}}
			/>
		</div>
	);
});

const formatNumLocale = (price: number, decimals: number): string => {
	return new Intl.NumberFormat(navigator.language, {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	}).format(price);
};

Shop.displayName = 'Shop';

// src/pages/ShopPage.tsx
import React from 'react';
import { Shop } from '../components/Shop/Shop';
import { Fighter } from '../store/types/fighterTypes';

type ShopPageProps = {
	fighter: Fighter | null;
};

const ShopPage: React.FC<ShopPageProps> = ({ fighter }) => {
	return (
		<div className="mt-2">
			<Shop fighter={fighter} />
		</div>
	);
};

export default ShopPage;

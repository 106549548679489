// src/pages/CombatPage.tsx
import React, { useEffect, useState } from 'react';
import { Fighter } from '../store/types/fighterTypes';

import { useParams, useNavigate } from 'react-router-dom';
import '../css/Sponsor.css';
import { Button } from '../components/Button/Button';
import { ProgressBar } from '../components/ProgressBar/ProgressBar';
import { eventCloud } from '../eventCloud';

import { ReactComponent as CreditsIcon } from '../assets/icons/svg/credits-blue.svg';
import { ReactComponent as SponsorPoster } from '../assets/icons/svg/sponsor-poster.svg';
import { ReactComponent as SponsorCrown } from '../assets/icons/svg/sponsor-crown.svg';

type SponsorPageProps = {
	fighter: Fighter | null;
};

function Countdown({ expires }: { expires: Date }): JSX.Element {
	const [timeLeft, setTimeLeft] = useState<string>(() => {
		const now = new Date().getTime();
		const distance = expires.getTime() - now;
		if (distance < 0) return 'Expired';

		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);
		return `${days}d ${hours}h ${minutes}m ${seconds}s`;
	});

	useEffect(() => {
		const timer = setInterval(() => {
			const now = new Date().getTime();
			const distance = expires.getTime() - now;

			if (distance < 0) {
				clearInterval(timer);
				setTimeLeft('Expired');
			} else {
				const days = Math.floor(distance / (1000 * 60 * 60 * 24));
				const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
				const seconds = Math.floor((distance % (1000 * 60)) / 1000);
				setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
			}
		}, 1000);

		return () => clearInterval(timer); // Cleanup on unmount
	}, [expires]);

	return <span>{timeLeft}</span>;
}

const SponsorPage: React.FC<SponsorPageProps> = ({ fighter }) => {
	const handleBuyPackage = () => {
		eventCloud.buySponsoredPackage();
	};

	return (
		<div>
			<div className="bg-yellow-500 bg-opacity-10 rounded-sm p-2 text-xxs sm:text-xs flex-col">
				<div className="flex items-center gap-x-2">
					<SponsorCrown />

					{fighter?.sponsor_expires && new Date(fighter.sponsor_expires) > new Date() ? (
						<div className="w-full flex justify-between">
							<div>Active package expires in</div>
							<div className="font-bold">
								<Countdown expires={new Date(fighter.sponsor_expires)} />
							</div>
						</div>
					) : (
						<div>No active package</div>
					)}
				</div>
				{/*
				{fighter?.sponsor_expires && new Date(fighter.sponsor_expires) > new Date() && (
					<div className="mt-1 h-full">
						<ProgressBar
						    progress={
						        (Math.max(0, Math.ceil((new Date(fighter.sponsor_expires).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)))) / 35 * 100
						    }
							height="h-1.5"
							color="bg-yellow-500"
							bgColor="bg-yellow-500 bg-opacity-10"
						/>
					</div>
				)}*/}
			</div>
			<div className="flex flex-col sm:flex-row w-full border border-blue-550 rounded-sm sponsor-bg p-4 sm:p-8 font-bold mt-4">
				<div className="order-2 sm:order-1 sm:w-1/2 flex flex-col flex-grow h-full">
					<div className="">
						<div className="text-base sm:text-2xl text-blue-550">
							BUY THE <span className="text-yellow-500">VIP</span> PACKAGE
						</div>

						<div className="text-xxs sm:text-base flex flex-col gap-y-1 mt-6">
							<div>
								<span className="text-blue-550">2X</span> Health/Mana Regen Rate
							</div>
							<div>
								<span className="text-blue-550">+30%</span> Credits
							</div>
							<div>
								<span className="text-blue-550">+30%</span> Experience
							</div>

							<div>
								<span className="text-blue-550">+5%</span> Item drop rate
							</div>
							<div>
								<span className="text-blue-550">+5%</span> Success rate when upgrading items
							</div>
						</div>

						<div className="flex justify-between mt-4">
							<div>
								<div className="text-gray-200 text-xxs sm:text-xs">Duration</div>
								<div className="text-xxs sm:text-xs">7 Days</div>
							</div>

							<div>
								<div className="text-gray-200 text-xxs sm:text-xs">Price</div>
								<div className="flex gap-x-1 items-center  text-xxs sm:text-xs">
									<CreditsIcon /> 100 000
								</div>
							</div>
						</div>

						<div className="mt-2">
							<Button onClick={handleBuyPackage}>BUY PACKAGE</Button>
						</div>
						<div className="text-xxxs sm:text-xs mt-2 text-gray-200">
							* If you already have an active sponsor package, buying will add an additional{' '}
							<span className="text-white">7 days</span> to the current package
						</div>
					</div>
				</div>

				<div className="order-1 sm:order-2 sm:w-1/2 flex items-center justify-end">
					<SponsorPoster className="h-[100px] xs:h-[220px] sm:h-[300px]" />
				</div>
			</div>
		</div>
	);
};

export default SponsorPage;

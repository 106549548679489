// ItemPopover.tsx

import React, { forwardRef, useState, useEffect } from 'react';
import { Item, Fighter, Account } from '../../store/types/fighterTypes';
import Popover from '@mui/material/Popover';
import classNames from 'classnames';
import { Button } from '../Button/Button';
import Modal from 'react-modal';
import { ItemThumbnail } from '../Item/ItemThumbnail';
import { ItemUpgradeLevel } from '../Item/ItemUpgradeLevel';
import { ConfirmPopup } from '../Shared/ConfirmPopup';
import { eventCloud } from '../../eventCloud';
import { getClassName } from '../../helpers/getClassName';

import { ReactComponent as CloseIcon } from '../../assets/icons/svg/close.svg';
import { ReactComponent as DownArrow } from '../../assets/icons/svg/down-arrow.svg';
import { ReactComponent as DefenceIcon } from '../../assets/icons/svg/defence.svg';
import { ReactComponent as AttackIcon } from '../../assets/icons/svg/attack.svg';
import { ReactComponent as UpgradeIcon } from '../../assets/icons/svg/upgrade.svg';
import { ReactComponent as CreditsIcon } from '../../assets/icons/svg/credits-blue.svg';
import { ReactComponent as LevelLine } from '../../assets/icons/svg/level-mark.svg';
import { ReactComponent as SkillIcon } from '../../assets/icons/svg/skill.svg';

type ItemPopoverProps = {
	selectedItem: Item | null;
	anchorEl: HTMLElement | null;
	handleUpgradeItemLevel?: (id: string) => void | null;
	handleEquipItem?: (id: string) => void | null;
	handleLiquidateItem?: (id: string) => void | null;
	handleUnlist?: (id: string) => void | null;
	handleUnequipItem?: (id: string) => void | null;
	handleConsumeItem?: (id: string) => void | null;
	handleBuyShopItem?: (item: Item) => void | null;
	handleBuyMarketItem?: (id: string) => void | null;
	setItemToList?: (item: Item) => void | null;
	setIsListingModalOpen?: (val: boolean) => void | null;
	slot?: string | null;

	onClose: any | null;

	fighter?: Fighter | null;
	account?: Account | null;

	anchor?: any;
};

export const ItemPopover = forwardRef<HTMLDivElement, ItemPopoverProps>(
	(
		{
			selectedItem,
			anchorEl,
			handleUpgradeItemLevel,
			fighter,
			account,
			handleEquipItem,
			handleLiquidateItem,
			handleUnlist,
			handleConsumeItem,
			handleUnequipItem,
			handleBuyShopItem,
			handleBuyMarketItem,
			setItemToList,
			setIsListingModalOpen,
			onClose,
			slot,
			anchor = 'right',
			...rest
		},
		ref
	) => {
		const [isUpgradeItemModalOpen, setIsUpgradeItemModalOpen] = useState(false);
		const [upgradingItem, setUpgradingItem] = useState<Item | null>(null);
		const [burningItem, setBurningItem] = useState<Item | null>(null);
		const [isConfirmBurnOpopupOpen, setIsConfirmBurnOpopupOpen] = useState(false);

		const handleCloseUpgradeModal = () => {
			setIsUpgradeItemModalOpen(false);
			setUpgradingItem(null);
		};

		return (
			<>
				<ItemUpgradeLevel
					isOpen={isUpgradeItemModalOpen}
					account={account}
					onClose={handleCloseUpgradeModal}
					handleUpgradeItemLevel={handleUpgradeItemLevel}
					selectedItem={upgradingItem}
					fighter={fighter}
				/>
				<Popover
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					onClose={() => {
						onClose();
					}}
					anchorOrigin={{
						vertical: 'top',
						horizontal: anchor,
					}}
				>
					{selectedItem && (
						<div className=" text-xxs sm:text-sm bg-black max-w-[400px] border border-gray-550 rounded-sm">
							<div className="bg-gray-550 text-white p-4">
								<div className="flex  font-bold  justify-between items-center">
									<div className="flex-grow">
										{selectedItem.name}
										{selectedItem.level_upgradeable && <span> +{selectedItem.level}</span>}
									</div>
									<div onClick={onClose} className="cursor-pointer active:top-px">
										<CloseIcon />
									</div>
								</div>
								<div className="flex justify-between font-regular mt-2 items-center">
									<div className="text-blue-550">{selectedItem.category.toUpperCase()}</div>
									<div>
										{selectedItem.level_upgradeable && handleUpgradeItemLevel && (
											<div
												onClick={() => {
													if (selectedItem.level == 15) return;
													setUpgradingItem(selectedItem);
													onClose();
													setIsUpgradeItemModalOpen(true);
												}}
												className={classNames(
													`font-bold flex items-center cursor-pointer select-none ${selectedItem.level < 15 ? 'text-blue-550' : 'text-gray-200'}`
												)}
											>
												<div>Upgrade Level</div>
												<UpgradeIcon width={18} height={18} />
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="text-gray-200 p-4 space-y-4 flex flex-col">
								<div className="max-w-[calc(40%)] flex justify-center ">
									<ItemThumbnail 
										item={selectedItem}
									/>
								</div>

								<div className="text-white whitespace-wrap">{selectedItem.description}</div>
								{selectedItem.is_equipable && selectedItem.parameters && (
									<>
										<div className="flex flex-col gap-y-2">
											{selectedItem.parameters[selectedItem.level].defence > 0 && (
												<div className="flex items-center space-x-2">
													<div className="text-gray-200">
														<DefenceIcon width={24} height={24} />
													</div>
													<div>
														<div className="text-xs">Defence</div>
														<div className="text-green-500 font-bold text-m flex items-center">
															<div>
																+{selectedItem.parameters[selectedItem.level].defence}
															</div>
															<div className="ml-1">
																<DownArrow
																	width={10}
																	height={10}
																	className="rotate-180 text-green-500"
																/>
															</div>
														</div>
													</div>
												</div>
											)}
											{selectedItem.parameters[selectedItem.level].min_damage > 0 && (
												<div className="flex items-center space-x-2">
													<div className="text-gray-200">
														<AttackIcon width={24} height={24} />
													</div>
													<div>
														<div className="text-xs">Damage</div>
														<div className="text-green-500 font-bold text-m flex items-center">
															<div>
																+
																{selectedItem.parameters[selectedItem.level].min_damage}
																-
																{selectedItem.parameters[selectedItem.level].max_damage}
															</div>
															<div className="ml-1">
																<DownArrow
																	width={10}
																	height={10}
																	className="rotate-180 text-green-500"
																/>
															</div>
														</div>
													</div>
												</div>
											)}
											{selectedItem.parameters[selectedItem.level].wiz_damage > 0 && (
												<div className="flex items-center space-x-2">
													<div className="text-gray-200">
														<SkillIcon width={24} height={24} />
													</div>
													<div>
														<div className="text-xs">Wiz Damage Increase</div>
														<div className="text-green-500 font-bold text-m flex items-center">
															<div>
																+
																{selectedItem.parameters[selectedItem.level].wiz_damage}
																%
															</div>
															<div className="ml-1">
																<DownArrow
																	width={10}
																	height={10}
																	className="rotate-180 text-green-500"
																/>
															</div>
														</div>
													</div>
												</div>
											)}
										</div>
										{selectedItem.parameters[selectedItem.level].required_level +
											selectedItem.parameters[selectedItem.level].required_strength +
											selectedItem.parameters[selectedItem.level].required_agility +
											selectedItem.parameters[selectedItem.level].required_intelligence >
											0 && (
											<div className="space-y-2">
												<div className="flex mt-4 items-center">
													<div className="font-regular text-gray-200">EQUIP REQUIREMENTS</div>
													<div className="flex-grow border-b border-gray-550 ml-2" />
												</div>
												<div>
													{selectedItem.parameters[selectedItem.level].required_level > 0 && (
														<div className="font-regular text-white">
															<span
																className={classNames(
																	{
																		'text-red-550':
																			Number(fighter?.level) <
																			selectedItem.parameters[selectedItem.level]
																				.required_level,
																	},
																	{
																		'text-green-500':
																			Number(fighter?.level) >=
																			selectedItem.parameters[selectedItem.level]
																				.required_level,
																	}
																)}
															>
																Character Level
															</span>{' '}
															<span
																className={classNames(
																	'font-bold',
																	{
																		'text-red-550':
																			Number(fighter?.level) <
																			selectedItem.parameters[selectedItem.level]
																				.required_level,
																	},
																	{
																		'text-green-500':
																			Number(fighter?.level) >=
																			selectedItem.parameters[selectedItem.level]
																				.required_level,
																	}
																)}
															>
																{
																	selectedItem.parameters[selectedItem.level]
																		.required_level
																}
															</span>
														</div>
													)}
													{selectedItem.parameters[selectedItem.level].required_strength >
														0 && (
														<div className="font-regular text-white">
															<span
																className={classNames(
																	{
																		'text-red-550':
																			Number(fighter?.strength) <
																			selectedItem.parameters[selectedItem.level]
																				.required_strength,
																	},
																	{
																		'text-white':
																			Number(fighter?.strength) >=
																			selectedItem.parameters[selectedItem.level]
																				.required_strength,
																	}
																)}
															>
																Strength
															</span>{' '}
															<span
																className={classNames(
																	'font-bold',
																	{
																		'text-red-550':
																			Number(fighter?.strength) <
																			selectedItem.parameters[selectedItem.level]
																				.required_strength,
																	},
																	{
																		'text-white':
																			Number(fighter?.strength) >=
																			selectedItem.parameters[selectedItem.level]
																				.required_strength,
																	}
																)}
															>
																{
																	selectedItem.parameters[selectedItem.level]
																		.required_strength
																}
															</span>
														</div>
													)}
													{selectedItem.parameters[selectedItem.level].required_agility >
														0 && (
														<div className="font-regular text-white">
															<span
																className={classNames(
																	{
																		'text-red-550':
																			Number(fighter?.agility) <
																			selectedItem.parameters[selectedItem.level]
																				.required_agility,
																	},
																	{
																		'text-white':
																			Number(fighter?.agility) >=
																			selectedItem.parameters[selectedItem.level]
																				.required_agility,
																	}
																)}
															>
																Agility
															</span>{' '}
															<span
																className={classNames(
																	'font-bold',
																	{
																		'text-red-550':
																			Number(fighter?.agility) <
																			selectedItem.parameters[selectedItem.level]
																				.required_agility,
																	},
																	{
																		'text-white':
																			Number(fighter?.agility) >=
																			selectedItem.parameters[selectedItem.level]
																				.required_agility,
																	}
																)}
															>
																{
																	selectedItem.parameters[selectedItem.level]
																		.required_agility
																}
															</span>
														</div>
													)}
													{selectedItem.parameters[selectedItem.level].required_intelligence >
														0 && (
														<div className="font-regular text-white">
															<span
																className={classNames(
																	{
																		'text-red-550':
																			Number(fighter?.intelligence) <
																			selectedItem.parameters[selectedItem.level]
																				.required_intelligence,
																	},
																	{
																		'text-white':
																			Number(fighter?.intelligence) >=
																			selectedItem.parameters[selectedItem.level]
																				.required_intelligence,
																	}
																)}
															>
																Intelligence
															</span>{' '}
															<span
																className={classNames(
																	'font-bold',
																	{
																		'text-red-550':
																			Number(fighter?.intelligence) <
																			selectedItem.parameters[selectedItem.level]
																				.required_intelligence,
																	},
																	{
																		'text-white':
																			Number(fighter?.intelligence) >=
																			selectedItem.parameters[selectedItem.level]
																				.required_intelligence,
																	}
																)}
															>
																{
																	selectedItem.parameters[selectedItem.level]
																		.required_intelligence
																}
															</span>
														</div>
													)}

													{selectedItem.is_equipable && selectedItem.class && (
														<div className="font-regular text-white mt-4">
															<span
																className={classNames(
																	{
																		'text-red-550':
																			Number(fighter?.class) <
																			selectedItem.parameters[selectedItem.level]
																				.required_intelligence,
																	},
																	{
																		'text-white':
																			Number(fighter?.intelligence) >=
																			selectedItem.parameters[selectedItem.level]
																				.required_intelligence,
																	}
																)}
															>
																{selectedItem.class.map((c, index) => (
																	<div
																		key={index}
																		className={classNames(
																			`text-center ${fighter?.class != c && 'text-red-550'}`
																		)}
																	>
																		Can be equipped by {getClassName(c)}
																	</div>
																))}
															</span>
														</div>
													)}
												</div>
											</div>
										)}
									</>
								)}
								{selectedItem?.has_skill && (
									<div className="flex flex-col justify-center text-blue-550">
										<div className="text-center">Skill (special increased damage attack)</div>
									</div>
								)}
								{selectedItem?.has_luck && (
									<div className="flex flex-col justify-center text-blue-550">
										<div className="text-center">Luck (success rate of Jewel of Soul +25%)</div>
										<div className="text-center">Luck (critical damage rate +5%)</div>
									</div>
								)}
								<div>
									<div className="space-y-2">
										{selectedItem.is_equipable && handleEquipItem && (
											<Button
												size="large"
												onClick={() => {
													handleEquipItem(selectedItem.id);
													onClose();
												}}
											>
												EQUIP ITEM
											</Button>
										)}

										{handleUnequipItem && slot && (
											<Button
												size="large"
												variant="basic"
												onClick={() => {
													handleUnequipItem(slot);
													onClose();
												}}
											>
												UNEQUIP
											</Button>
										)}

										{selectedItem.is_consumable && handleConsumeItem && (
											<Button
												size="large"
												variant="green"
												onClick={() => {
													handleConsumeItem(selectedItem.id);
													onClose();
												}}
											>
												CONSUME
											</Button>
										)}

										{selectedItem.in_shop && handleBuyShopItem && (
											<Button
												size="large"
												variant="green"
												onClick={() => {
													handleBuyShopItem(selectedItem);
													onClose();
												}}
											>
												BUY
											</Button>
										)}

										{handleBuyMarketItem && (
											<Button
												size="large"
												onClick={() => {
													handleBuyMarketItem(selectedItem.id);
													onClose();
												}}
											>
												BUY
											</Button>
										)}

										{selectedItem.is_listed && handleUnlist && (
											<Button
												size="large"
												variant="black"
												onClick={() => {
													handleUnlist(selectedItem.id);
													onClose();
												}}
											>
												UNLIST FROM MARKET
											</Button>
										)}
										{/*: (
											<>
												{setItemToList && setIsListingModalOpen && (
													<Button
														size="large"
														variant="black"
														onClick={() => {
															setItemToList(selectedItem);
															setIsListingModalOpen(true);
															onClose();
														}}
													>
														LIST TO MARKET
													</Button>
												)}
											</>
										)}*/}

										{handleLiquidateItem && (
											<Button
												size="large"
												variant="red"
												onClick={() => {
													setBurningItem(selectedItem);
													setIsConfirmBurnOpopupOpen(true);
													onClose();
												}}
											>
												SELL FOR {selectedItem.price} CR
											</Button>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
				</Popover>
				<ConfirmPopup
					isOpen={isConfirmBurnOpopupOpen}
					onConfirm={() => {
						if (!handleLiquidateItem || !burningItem) return;
						handleLiquidateItem(burningItem.id);
						setBurningItem(null);
					}}
					onClose={() => {
						setIsConfirmBurnOpopupOpen(false);
						setBurningItem(null);
					}}
					title="Confirm item burn"
					text="Are you sure you want to burn this item? This action is irreversible."
				/>
			</>
		);
	}
);

ItemPopover.displayName = 'ItemPopover';

// src/pages/ProfilePageWide.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eventCloud } from '../eventCloud';
import { Fighter, Combat, Account } from '../store/types/fighterTypes';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../components/Button/Button';
import classNames from 'classnames';
import {
	addActiveCombat,
	removeActiveCombat,
	addFinishedCombat,
	setActiveCombats,
	setFinishedCombats,
} from '../store/reducers/combatReducer';
import History from '../components/History/History';
import Inventory from '../components/Inventory/Inventory';
import { Tabs } from '../components/Shared/Tabs';
import FighterProfile from '../components/Fighter/FighterProfile';
import FighterStatsVertical from '../components/Fighter/FighterStatsVertical';
import SponsorIndicator from '../components/Shared/SponsorIndicator';
import { FighterPopover } from '../components/Fighter/FighterPopover';
import { Market } from '../components/Market/Market';

import { ReactComponent as AttackIcon } from '../assets/icons/svg/attack.svg';
import { ReactComponent as DefeatIcon } from '../assets/icons/svg/defeat.svg';
import { ReactComponent as DrawIcon } from '../assets/icons/svg/draw.svg';
import { ReactComponent as AbandonIcon } from '../assets/icons/svg/abandon.svg';
import { ReactComponent as IconSpinner } from '../assets/icons/svg/spinner.svg';

type ProfilePageProps = {
	fighter: Fighter | null;
	account: Account | null;
};

const ProfilePageWide: React.FC<ProfilePageProps> = ({ fighter, account }) => {
	const navigate = useNavigate();
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 575);
	const [selectedTab, setSelectedTab] = useState('History');

	useEffect(() => {
		const handleResize = () => {
			setIsLargeScreen(window.innerWidth > 575);
		};
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleFight = () => {
		eventCloud.sendFight();
	};

	if (!fighter) {
		return (
			<div className="flex flex-col justify-center items-center min-h-[500px]  text-gray-200 text-base xs:text-2xl">
				<div className="h-full">
					<div className="text-center">No Character Selected</div>
					<div className="text-xs xs:text-lg text-center">Please select a character or create one.</div>
				</div>
			</div>
		);
	}

	return (
		<div className="h-full">
			<div className="flex flex-col xs:flex-row xs:space-x-4">
				<div className="flex-grow order-2 xs:order-1 w-full h-full">
					{/*<div className="text-xs text-center text-white bg-gray-550 p-1 mb-1">
						<a href="https://dmex.app" rel="noreferrer" target="_blank" className="hover:underline">
							<span className="text-purple-900 font-bold">AD:</span> Trade <b>crypto</b> futures with up
							to <span className="text-blue-550 font-bold">500x leverage</span> and no KYC on{' '}
							<span className="text-yellow-500 font-bold">DMEX</span>
						</a>
					</div>*/}
					<div className="xs:mt-0">
						<Tabs
							variant="block"
							items={['INVENTORY', 'STATS', 'HISTORY']}
							noLine={true}
							onSelect={(tab: string) => {
								setSelectedTab(tab);
							}}
						/>
					</div>

					<div className="xxs:overflow-y-auto h-full md:pt-4">
						{selectedTab === 'STATS' && (
							<div className="w-full overflow-x-auto flex flex-col gap-y-1 text-sm mt-4">
								<FighterStatsVertical fighter={fighter} />
							</div>
						)}

						{selectedTab == 'HISTORY' && (
							<div className="flex flex-col space-y-4 h-full">
								<div className="flex flex-wrap gap-x-1 gap-y-1 w-full justify-between">
									{/* Stat Boxes */}
									<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-blue-550 border rounded-sm p-2">
										<div className="flex gap-x-1 text-xs md:text-m text-blue-550">
											<AttackIcon />
											Victories
										</div>
										<div className="text-xl md:text-2xl font-bold">{fighter?.victories}</div>
									</div>
									<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-red-550 border rounded-sm p-2">
										<div className="flex gap-x-1 text-xxs md:text-m text-red-550">
											<DefeatIcon />
											Defeats
										</div>
										<div className="text-xl md:text-2xl font-bold ">{fighter?.defeats}</div>
									</div>
									<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-yellow-500 border rounded-sm p-2">
										<div className="flex gap-x-1 text-xxs md:text-m text-yellow-500">
											<DrawIcon />
											Draws
										</div>
										<div className="text-xl md:text-2xl font-bold">{fighter?.draws}</div>
									</div>
									<div className="flex flex-col gap-y-1 w-[calc(50%-0.15rem)] md:w-[calc(25%-0.2rem)] bg-gray-550 bg-opacity-50 border-gray-200 border rounded-sm p-2">
										<div className="flex gap-x-1 text-xxs md:text-m text-gray-200">
											<AbandonIcon />
											Abandons
										</div>
										<div className="text-xl md:text-2xl font-bold">{fighter?.abandons}</div>
									</div>
								</div>
								<div className="flex-grow md:overflow-y-auto">
									<History fighter={fighter} />
								</div>
							</div>
						)}

						{selectedTab === 'INVENTORY' && (
							<div className="h-full">
								<Inventory account={account} fighter={fighter} />
							</div>
						)}
					</div>
				</div>

				<div className="order-1 xs:order-2 flex justify-center sm:w-[calc(30%)] mb-1">
					<div className="flex flex-col gap-y-2 w-full">
						<div className="flex justify-center">
							<div className="w-full">
								<Button
									onClick={handleFight}
									size="large"
									className="py-3 text-base"
									variant={fighter?.in_combat ? 'red' : 'basic'}
								>
									{fighter?.in_combat ? (
										<div className="flex justify-center gap-x-2">
											<IconSpinner width={16} />
											<div>SEARCHING OPPONENT</div>
										</div>
									) : (
										<span>FIGHT</span>
									)}
								</Button>
							</div>
						</div>
						<div className="flex justify-center max-w-full max-h-[calc(60vh)] sm:max-h-full">
							<FighterProfile
								hideHealthMana={true}
								equippable={true}
								allowItemPopover={true}
								fighter={fighter}
							/>
						</div>
						<div>
							<SponsorIndicator fighter={fighter} />
						</div>
						<div className="w-[calc(100%)]">
							<Button
								variant="black"
								onClick={() => {
									navigate('/aff');
								}}
							>
								<div className="flex items-center justify-center gap-x-1">REFER A FRIEND</div>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfilePageWide;

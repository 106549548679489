// src/pages/AffiliatePage.tsx

import React, { useState, useEffect, useRef } from 'react';
import { eventCloud } from '../eventCloud';
import { Account } from '../store/types/fighterTypes';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../components/Button/Button';
import classNames from 'classnames';
import { getBackendUrl } from '../helpers/getBackendUrl';

import { ReactComponent as CopyIcon } from '../assets/icons/svg/copy.svg';

type AffiliatePageProps = {
	account: Account | null;
};

const AffiliatePage: React.FC<AffiliatePageProps> = ({ account }) => {
	const [affiliates, setAffiliates] = useState<Account[] | null>(null);
	const [totalAffiliates, setTotalAffiliates] = useState(0);
	const [totalSpent, setTotalSpent] = useState(0);
	useEffect(() => {
		eventCloud.requestAffiliates();

		const handleAffiliatesUpdate = (result: any) => {
			//setHitScreenVisible(false);
			console.log('[handleAffiliatesUpdate] ', result);
			// Redirect to the combat page
			setAffiliates(result.latest);
			setTotalAffiliates(result.total);
			setTotalSpent(result.total_spent);
		};

		eventCloud.on('affiliates_update', handleAffiliatesUpdate);

		return () => {
			eventCloud.off('affiliates_update', handleAffiliatesUpdate);
		};
	}, []);

	const handleCopy = (text: string | undefined) => {
		if (!text) return;
		navigator.clipboard.writeText(text);
	};

	return (
		<div className="h-full mt-2">
			<div className="flex gap-x-2 mb-2">
				<div className="w-full">
					<div className="text-xxs text-gray-200 mb-2">
						Invite friends using the link below and <span className="text-blue-550">earn 30%</span> of all
						credits they spend in the shop, on wagers (applicable to 10% of the wager value), and on VIP
						packages.
					</div>
					<div
						className="relative active:top-px text-gray-200 flex items-center gap-x-2 select-none cursor-pointer"
						onClick={() => {
							handleCopy(`https://combats.game/?r=${account?.address}`);
						}}
					>
						Your Link
						<CopyIcon />
					</div>
					<div className="border-gray-550 text-xxs border rounded-md p-1">
						https://combats.game/?r={account?.address}
					</div>
				</div>
			</div>
			<div className="flex gap-x-2">
				<div className="w-1/3 border border-gray-200 rounded-sm p-4">
					<div className="text-xxxs sm:text-base text-gray-200">REFERED</div>
					<div className="text-xs sm:text-xl">{shortenNumber(totalAffiliates)}</div>
				</div>

				<div className="w-1/3 border border-yellow-500 rounded-sm p-4">
					<div className="text-xxxs sm:text-base text-yellow-500">SPENT</div>
					<div className="text-xs sm:text-xl">{shortenNumber(totalSpent)}</div>
				</div>

				<div className="w-1/3 border border-green-500 rounded-sm p-4">
					<div className="text-xxxs sm:text-base text-green-500">30% SHARE</div>
					<div className="text-xs sm:text-xl">{shortenNumber(totalSpent * 0.3)}</div>
				</div>
			</div>
			<div className="flex flex-col h-full  overflow-y-auto mt-2">
				<div className="flex flex-col">
					<div className="h-full">
						<div className="h-full flex flex-col">
							<table className="w-full table-fixed">
								<thead className="bg-gray-550 bg-opacity-50 text-gray-200 font-bold text-xxs border-b border-gray-550 h-[27px]">
									<tr>
										<th className="text-left w-[120px] pl-2">JOINED</th>
										<th className="text-left max-w-full w-[200px]">ADDRESS</th>
										<th className="text-right w-[80px]">EARNED</th>
										<th className="text-right w-[80px]">SPENT</th>
										<th className="text-right w-[100px] pr-2">YOUR SHARE</th>
									</tr>
								</thead>
							</table>

							{!affiliates ? (
								<div className="text-center text-gray-200 text-m m-10">Nothing to show</div>
							) : (
								<div className="flex-1">
									<table className="w-full table-fixed">
										<tbody className="text-white text-xs font-regular bg-gray-550">
											{affiliates.map((item, index) => (
												<tr key={index} className="border-b border-black">
													<td className="text-left w-[120px] pl-2 font-mono">
														{formatTimestampToLocaleTime(item.created_at)}
													</td>
													<td className="text-left max-w-full w-[200px] text-gray-200">
														{toShortenedAddress(item.address)}
													</td>
													<td className="text-right w-[80px] text-gray-200">{item.earned}</td>
													<td className="text-right w-[80px] text-yellow-550">
														{item.spent}
													</td>
													<td className="text-right w-[100px] pr-2 text-green-550">
														{Math.floor(Number(item.spent * 0.3))}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

function shortenNumber(num: number) {
	if (num < 1000) {
		return num.toString();
	} else if (num >= 1000 && num < 1000000) {
		return Math.round(num / 1000) + 'K';
	} else if (num >= 1000000) {
		return Math.round(num / 1000000) + 'M';
	}
}

const toShortenedAddress = (a: string) => {
	return `${a.slice(0, 7)}…${a.slice(-5)}`;
};

function formatTimestampToLocaleTime(timestamp: number) {
	return new Date(timestamp).toLocaleTimeString(undefined, {
		day: '2-digit',
		month: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	});
}

export default AffiliatePage;

import React from 'react';
import HomeHeader from '../components/Shared/HomeHeader';
import { Button } from '../components/Button/Button';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CreditsToEthIcon } from '../assets/icons/svg/credits-to-eth.svg';

import MarketPlaceImg from '../assets/png/home-marketplace.png';
import SpinwheelImg from '../assets/png/home-spinwheel.png';
import RarityImg from '../assets/png/home-rarity.png';
import UpgradeImg from '../assets/png/home-upgrade.png';
import DuelImg from '../assets/png/home-duel.png';
import ConvertImg from '../assets/png/home-convert.png';
import AvatarsImg from '../assets/png/home-avatars.png';

const HomePage: React.FC = () => {
	const navigate = useNavigate();
	return (
		<div
			className="h-full w-full"
			style={{
				backgroundImage: 'url("/assets/--home-bg.svg")',
				backgroundRepeat: 'repeat',
				backgroundSize: 'auto',
			}}
		>
			<section className="min-h-screen flex flex-col items-center justify-center mx-10">
				<div>
					<img src={AvatarsImg} alt="Avatars" width={790} />
				</div>
				<div
					className="text-center font-bold text-3xl lg:text-8xl w-full flex flex-col justify-center items-center"
					style={{
						backgroundImage: 'url("/assets/--home-top-bg.png")',
						backgroundPosition: 'center',
						backgroundSize: 'auto 100%',
						backgroundRepeat: 'no-repeat',
						height: 'auto',
						aspectRatio: 'auto 254', // This maintains the aspect ratio of the image as the width shrinks
					}}
				>
					<div className="p-8">
						YOUR VICTORIES
						<br />
						<span className="text-blue-550">YOUR REWARDS</span>
					</div>
				</div>
				<div className="text-base text-center lg:text-xxl mt-4">Conquer your opponents, Earn Real Ethereum</div>
				<div className="mt-10 w-[300px]">
					<Button
						size="huge"
						className="p-4"
						onClick={() => {
							navigate(`/profile`);
						}}
					>
						FIGHT NOW
					</Button>
				</div>
			</section>

			{/* Responsive Layout for Sections */}
			<section className="min-h-[calc(100vh-150px)] flex flex-col lg:flex-row items-center justify-center mx-2 my-10">
				<div className="text-center text-4xl w-full h-full flex flex-col lg:flex-row space-y-4 lg:space-y-0">
					{/* Avatar Section */}
					<div className="w-full lg:w-1/3 order-1 lg:order-2 flex flex-col bg-gray-100 border border-gray-550 lg:mx-4">
						<div className="flex items-center justify-center mt-6">DUEL</div>
						<div>
							<img src={DuelImg} alt="Avatar" className="w-full" />
						</div>
						<div className="p-10">
							<div className="font-bold text-xl mt-4 text-left">BLOACKCHAIN FIGHTING GAME</div>
							<div className="text-base font-regular mt-2 text-left">
								Combats is a browser fighting game running on a dedicated L3 blockchain. Play with other
								gamers to build your character and items set. Trade in with other players to get the
								best gear.
							</div>
							<div className="font-bold text-xl mt-8 text-left">BATTLE YOUR WAY</div>
							<div className="text-base font-regular mt-2 text-left">
								Multiple fight modes available. From the basic 1v1 to 10v10 team deathmatch for higher
								rewards.
							</div>
							<div className="text-base font-regular pt-10">
								<Button
									size="huge"
									className="p-6"
									onClick={() => {
										navigate(`/profile`);
									}}
								>
									FIGHT NOW
								</Button>
							</div>
						</div>
					</div>

					{/* Credits Section */}
					<div className="w-full lg:w-1/3 order-2 lg:order-1 flex flex-col space-y-4">
						<div className="flex-1 bg-gray-100 border border-gray-550 p-10 w-full">
							<div>
								<img src={ConvertImg} alt="Avatar" className="w-full" />
							</div>
							<div className="text-xl text-left font-bold mt-10">CONVERT CREDITS TO ETHEREUM</div>
							<div className="text-base text-left mt-2">
								Earn in-game credits and convert them into{' '}
								<span className="font-bold text-blue-550">Ethereum</span> on{' '}
								<span className="text-red-550 font-bold">OPTIMISM NETWORK</span>, turning your victories
								into real-world rewards!
							</div>
							<div className="text-base text-left mt-4">
								Credits can be instantly sold at a fixed rate of{' '}
								<span className="text-blue-550 font-bold">10000 CREDITS = 1 USD</span>.
							</div>
							<div className="text-yellow-500 text-base text-left mt-4">
								No <strong>tokenomics</strong>. No <strong>limits</strong>. No <strong>minimum</strong>{' '}
								amounts.
							</div>
						</div>
						<div className="flex-1 bg-gray-100 border border-gray-550 p-10 w-full">
							<div className="text-center flex justify-center">
								<img src={MarketPlaceImg} alt="Marketplace" className="w-full" />
							</div>
							<div className="text-xl font-bold text-left mt-4">IN-GAME MARKETPLACE</div>
							<div className="text-base text-left mt-2">
								Sell/Buy items on the in-game marketplace. Items can be bought only from other players.
								There is no in-game item shop.
							</div>
						</div>
					</div>

					{/* Items Section */}
					<div className="w-full lg:w-1/3 order-3 lg:order-3 flex flex-col space-y-4">
						<div className="flex-1 bg-gray-100 border border-gray-550 p-10 text-center w-full">
							<div className="flex justify-center">
								<img src={SpinwheelImg} alt="Spinwheel" className="w-full" />
							</div>
							<div className="font-bold text-xl text-left mt-4">SPIN THE WHEEL OF FORTUNE</div>
							<div className="text-base text-left mt-2">
								Every win gives you a chance to spin the wheel for valuable rewards like armor, weapons,
								credits, and more!
							</div>
							<div className="text-base text-left mt-4 text-yellow-500">
								All in-game items are NFTs that can be traded with other players.
							</div>
						</div>
						<div className="flex-1 bg-gray-100 border border-gray-550 p-10 w-full">
							<div className="flex justify-center">
								<img src={RarityImg} alt="Rare Items" className="w-full" />
							</div>
							<div className="font-bold text-xl text-left mt-4">RARE AND UNIQUE ITEMS</div>
							<div className="text-base text-left mt-2">
								Lucky warriors can win rare and unique items based on the defeated opponent level,
								giving you special options and an edge on the battlefield!
							</div>
						</div>
						<div className="flex-1 bg-gray-100 border border-gray-550 p-10 w-full">
							<div className="flex justify-center -mt-10">
								<img src={UpgradeImg} alt="Upgrade Items" className="w-full" />
							</div>
							<div className="font-bold text-xl text-left mt-4">UPGRADE YOUR GEAR</div>
							<div className="text-base text-left mt-2">
								Upgrade your armor, weapons, and gear using jewels to become stronger and win more
								battles!
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default HomePage;

// src/pages/MarketPage.tsx
import React from 'react';
import { Market } from '../components/Market/Market';
import { Fighter } from '../store/types/fighterTypes';

type MarketPageProps = {
	fighter: Fighter | null;
};

const MarketPage: React.FC<MarketPageProps> = ({ fighter }) => {
	return (
		<div>
			<Market fighter={fighter} />
		</div>
	);
};

export default MarketPage;

// FighterLevelUp.tsx

import React, { forwardRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button } from '../Button/Button';
import Modal from 'react-modal';
import { Fighter } from '../../store/types/fighterTypes';
import FighterStats from './FighterStats';

import { ReactComponent as CloseIcon } from '../../assets/icons/svg/close.svg';
import Confetti from 'react-dom-confetti';

type FighterLevelUpProps = {
	isOpen: boolean;
	onClose: any | null;

	fighter: Fighter | null;
};

export const FighterLevelUp = forwardRef<HTMLDivElement, FighterLevelUpProps>(
	({ fighter, isOpen, onClose, ...rest }, ref) => {
		const [fireConfetti, setFireConfetti] = useState(false);

		const confettiConfig = {
			angle: 74,
			spread: 500,
			startVelocity: 50,
			elementCount: 500,
			dragFriction: 0.13,
			duration: 5000,
			stagger: 1,
			width: '9px',
			height: '12px',
			perspective: 756,
			colors: [
				'#11DEEA',
				'#497FF2',
				'#9E68D5',
				'#94A2EE',
				'#3CCC90',
				'#8B8B90',
				'#FF6058',
				'#CE6DA8',
				'#FF8058',
				'#F0A64A',
				'#F0D352',
				'#B3AA8B',
			],
		};

		useEffect(() => {
			if (isOpen) {
				setFireConfetti(false);
				setTimeout(() => setFireConfetti(true), 25);
			} else {
				setFireConfetti(false);
			}
		}, [isOpen]);

		return (
			<>
				<Modal
					isOpen={isOpen}
					onRequestClose={onClose}
					className="fixed bottom-0 md:bottom-auto w-full  h-1/2 max-w-screen-md max-w-md mx-auto bg-black ring-1 ring-gray-540 outline-none"
					overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
				>
					<div
						className="w-full h-full p-2 flex flex-col justify-between"
						style={{
							backgroundImage: "url('/assets/--combat-claim-bg.png')",
							backgroundSize: 'cover',
							backgroundPosition: 'center',
						}}
					>
						<div className="flex justify-between text-xs sm:text-base items-center">
							<div>Level Up!</div>
							<div onClick={onClose}>
								<CloseIcon />
							</div>
						</div>
						<div className="flex justify-center relative">
							<Confetti active={fireConfetti} config={confettiConfig} />
						</div>
						<div className="flex justify-center items-center text-sm sm:text-2xl mt-4 text-blue-550">
							<div>You Have Reached</div>
						</div>
						<div className="flex justify-center items-center text-5xl font-bold text-white">
							<div>Level {fighter?.level}</div>
						</div>
						<div className="mt-6 mb-4 text-center">
							Available Points:{' '}
							<span className="text-blue-550 font-bold">{fighter?.available_stats}</span>
						</div>
						<div className="flex justify-center items-center mt-4 font-bold text-green-500">
							<Button size="large" onClick={onClose}>
								Continue
							</Button>
						</div>
					</div>
				</Modal>
			</>
		);
	}
);

FighterLevelUp.displayName = 'FighterLevelUp';

import React from 'react';

type ProgressBarProps = {
	progress: number; // Progress percentage (0-100)
	height?: string; // Optional: Height of the progress bar (e.g., 'h-4' for 1rem height)
	color?: string; // Optional: Background color of the progress bar
	bgColor?: string; // Optional: Background color of the progress bar
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
	progress,
	height = 'h-1', // Default height
	color = 'bg-white', // Default color
	bgColor = 'bg-gray-200', // Default color
}) => {
	return (
		<div className={`w-full rounded ${height} ${bgColor} shadow-inner`}>
			<div className={`rounded ${height} ${color}`} style={{ width: `${progress}%` }}></div>
		</div>
	);
};

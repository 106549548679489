import React, { useEffect, useState } from 'react';
import { Fighter } from '../../store/types/fighterTypes';

import { useParams, useNavigate } from 'react-router-dom';
import '../../css/Sponsor.css';
import { Button } from '../../components/Button/Button';
import { ProgressBar } from '../../components/ProgressBar/ProgressBar';
import { eventCloud } from '../../eventCloud';

import { ReactComponent as SponsorCrown } from '../../assets/icons/svg/sponsor-crown.svg';

type SponsorIndicatorProps = {
	fighter: Fighter | null;
};

function Countdown({ expires }: { expires: Date }): JSX.Element {
	const [timeLeft, setTimeLeft] = useState<string>(() => {
		const now = new Date().getTime();
		const distance = expires.getTime() - now;
		if (distance < 0) return 'Expired';

		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);
		return `${days}d ${hours}h ${minutes}m ${seconds}s`;
	});

	useEffect(() => {
		const timer = setInterval(() => {
			const now = new Date().getTime();
			const distance = expires.getTime() - now;

			if (distance < 0) {
				clearInterval(timer);
				setTimeLeft('Expired');
			} else {
				const days = Math.floor(distance / (1000 * 60 * 60 * 24));
				const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
				const seconds = Math.floor((distance % (1000 * 60)) / 1000);
				setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
			}
		}, 1000);

		return () => clearInterval(timer); // Cleanup on unmount
	}, [expires]);

	return <span>{timeLeft}</span>;
}

const SponsorIndicator: React.FC<SponsorIndicatorProps> = ({ fighter }) => {
	const navigate = useNavigate();
	return (
		<div className="bg-yellow-500 bg-opacity-10 rounded-sm p-2 text-xs flex-col">
			<div
				className="flex items-center gap-x-2 cursor-pointer relative active:top-px"
				onClick={() => {
					navigate('/sponsor');
				}}
			>
				<SponsorCrown />

				{fighter?.sponsor_expires && new Date(fighter.sponsor_expires) > new Date() ? (
					<div className="w-full flex justify-between">
						<div>VIP package expires in</div>
						<div className="font-bold">
							<Countdown expires={new Date(fighter.sponsor_expires)} />
						</div>
					</div>
				) : (
					<>
						<div>No active VIP package</div>
					</>
				)}
			</div>
		</div>
	);
};

export default SponsorIndicator;

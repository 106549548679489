import React from 'react';
import { NavLink } from 'react-router-dom';
import { Wallets } from '../Wallets/Wallets';
import { FighterSelector } from '../Fighter/FighterSelector';
import { ReactComponent as IconLogo } from '../../assets/icons/svg/logo.svg';
import { ReactComponent as XLogo } from '../../assets/icons/svg/x.svg';
import { ReactComponent as TelegramLogo } from '../../assets/icons/svg/telegram.svg';
import { Button } from '../Button/Button';
import { useNavigate } from 'react-router-dom';

const HomeHeader: React.FC = () => {
	const navigate = useNavigate();
	return (
		<header className="mb-2">
			<div className="flex justify-between">
				<NavLink className="p-2 px-4 flex-none justify-center items-center pt-4" to="/">
					<IconLogo className="w-118" />
				</NavLink>
				{/*<nav className="text-sm font-bold px-2 py-4 flex-grow text-center">
					<div className="px-4 space-x-4">
						<NavLink
							className={({ isActive }) =>
								isActive ? "mr-2 text-blue-550" : "mr-2"
							}
							to="/"
						>
							HOME
						</NavLink>
						<NavLink
							className={({ isActive }) =>
								isActive ? "mr-2 text-blue-550" : "mr-2"
							}
							to="/lobby"
						>
							FEATURES
						</NavLink>
						<NavLink
							className={({ isActive }) =>
								isActive ? "mr-2 text-blue-550" : "mr-2"
							}
							to="/inventory"
						>
							FOLLOW US
						</NavLink>
					</div>
				</nav>*/}

				<div className="p-2 flex items-center space-x-3">
					<div className="border-r border-gray-550 flex px-3 space-x-1">
						<div className=" bg-gray-550">
							<a target="_blank" rel="noreferrer" href="https://x.com/CombatsGame">
								<XLogo width={24} height={24} />
							</a>
						</div>
						<div className=" bg-gray-550">
							<a target="_blank" rel="noreferrer" href="https://t.me/combats_game">
								<TelegramLogo width={24} height={24} />
							</a>
						</div>
					</div>
					<Button
						onClick={() => {
							navigate(`/profile`);
						}}
					>
						LAUNCH GAME
					</Button>
				</div>
			</div>
		</header>
	);
};

export default HomeHeader;

export function getRegenMultiplier(level: number): number {
	switch (level) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 8;
		case 4:
			return 16;
		case 5:
			return 32;
		case 6:
			return 64;
	}

	return 0;
}

export function getNextRegenPrice(level: number): number {
	switch (level) {
		case 0:
			return 25;
		case 1:
			return 50;
		case 2:
			return 100;
		case 3:
			return 200;
		case 4:
			return 400;
		case 5:
			return 800;
	}

	return 0;
}

declare global {
	interface EthereumProvider {
		request: (args: { method: string; params?: object | any[] }) => Promise<any>;
		// You can add more properties based on your use case if needed
	}

	interface Window {
		ethereum?: EthereumProvider;
	}
}

export async function requestAccountAccess() {
	if (window.ethereum) {
		try {
			const accounts = await window.ethereum.request({
				method: 'eth_requestAccounts',
			});
			console.log('Access granted, accounts:', accounts);
			return accounts; // Returns the array of accounts, typically you'd use accounts[0] for the first account
		} catch (error) {
			console.error('Error requesting account access:', error);
			throw error; // Re-throw the error to be handled by the caller
		}
	} else {
		console.error('Ethereum object not found, make sure you have MetaMask installed.');
		throw new Error('Ethereum object not found');
	}
}

import classNames from 'classnames';
import React, { forwardRef } from 'react';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	icon?: React.ReactNode;
	variant?: string;
	size?: string;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({ children, className = '', variant = 'basic', size = 'regular', icon, ...rest }, ref) => {
		return (
			<button
				ref={ref}
				className={classNames(
					`button relative rounded-sm w-full text-center active:top-px`,
					{ 'p-2 text-xs': size === 'regular' },
					{ 'p-1 text-xxs': size === 'small' },
					{ 'p-2 text-m': size === 'large' },
					{ 'px-1 text-m': size === 'tiny' },
					{ 'px-1 text-xl': size === 'huge' },
					{ 'bg-blue-550 text-black': variant === 'basic' },
					{
						'bg-gray-200 bg-opacity-10 text-gray-550': variant === 'disabled',
					},
					{
						'text-blue-550 border border-blue-550': variant === 'black',
					},
					{
						'text-gray-200 border border-gray-200': variant === 'gray',
					},
					{
						'text-white bg-gray-550': variant === 'dark-gray',
					},
					{ 'bg-white text-black': variant === 'white' },
					{ 'bg-red-550 text-black': variant === 'red' },
					{ 'bg-purple-900 text-black': variant === 'purple' },
					{ 'bg-yellow-500 text-black': variant === 'yellow' },
					{ 'bg-yellow-500 bg-opacity-10 text-yellow-500': variant === 'gold' },
					{ 'bg-green-500 text-black': variant === 'green' },
					{ 'bg-blue-550 bg-opacity-10': variant === 'blue-trans' },
					{ 'bg-yellow-500 bg-opacity-10': variant === 'yellow-trans' },
					{ 'bg-green-500 bg-opacity-10': variant === 'green-trans' },
					{ 'bg-gray-200 bg-opacity-25': variant === 'gray-trans' },
					{ 'bg-gray-200 bg-opacity-10': variant === 'gray-dis' },
					{
						'bg-red-550 text-black bg-opacity-10': variant === 'red-trans',
					},
					className // Include any additional className passed
				)}
				{...rest}
			>
				<div className="flex whitespace-nowrap select-none cursor-pointer">
					{icon && <span className="button__icon mr-1">{icon}</span>}
					<div
						className={classNames(
							'font-bold flex-grow text-center',
							{
								'text-blue-550 text-xxs': variant === 'blue-trans',
							},
							{
								'text-red-550 text-xxs': variant === 'red-trans',
							},
							{
								'text-green-500 text-xxs': variant === 'green-trans',
							},
							{
								'text-gray-200 text-xxs': variant === 'gray-dis',
							},
							{
								'text-white text-xxs': variant === 'gray-trans',
							}
						)}
					>
						{children}
					</div>
				</div>
			</button>
		);
	}
);

Button.displayName = 'Button';

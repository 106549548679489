// ConfirmPopup.tsx

import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Button } from '../Button/Button';
import Modal from 'react-modal';

import { ReactComponent as CloseIcon } from '../../assets/icons/svg/close.svg';

type ConfirmPopupProps = {
	isOpen: boolean;
	onClose: any | null;
	onConfirm: any | null;

	title: string;
	text: string;
};

export const ConfirmPopup = forwardRef<HTMLDivElement, ConfirmPopupProps>(
	({ isOpen, onConfirm, onClose, text, title, ...rest }, ref) => {
		return (
			<>
				<Modal
					isOpen={isOpen}
					onRequestClose={onClose}
					className="w-[350px] max-w-screen-md max-w-md mx-auto mt-20 p-4 bg-black ring-1 ring-gray-540 outline-none"
					overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
				>
					<div className="flex justify-between items-center">
						<div>{title}</div>
						<div className="text-white cursor-pointer" onClick={onClose}>
							<CloseIcon width={18} height={18} />
						</div>
					</div>

					<div className="text-xs my-10">{text}</div>

					<div>
						<Button
							onClick={() => {
								onConfirm();
								onClose();
							}}
						>
							CONFIRM
						</Button>
					</div>
				</Modal>
			</>
		);
	}
);

ConfirmPopup.displayName = 'ConfirmPopup';

// ItemThumbnail.tsx

import React, { forwardRef } from 'react';
import { Item } from '../../store/types/fighterTypes';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as LevelLine } from '../../assets/icons/svg/level-mark.svg';
import Sparkles from 'react-sparkle';
import '../../css/Lamp.css';

type ItemThumbnailProps = {
	item: Item | null;
	onClick?: any;
	width?: string | number | undefined;
	height?: string | number | undefined;
	collected?: boolean;
	liquidated?: boolean;
	tooltip?: boolean;
	showLevel?: boolean;
};

export const ItemThumbnail = forwardRef<HTMLDivElement, ItemThumbnailProps>(
	(
		{
			item,
			onClick,
			width = '100%',
			height = '100%',
			collected,
			liquidated,
			showLevel = true,
			tooltip = false,
			...rest
		},
		ref
	) => {
		return (
			<div className="relative cursor-pointer active:top-px" onClick={onClick}>
				{/* Existing labels */}
				{item?.is_listed && (
					<div className="absolute text-xs sm:text-sm top-1/2 w-full text-center transform -translate-y-1/2 bg-black bg-opacity-80 text-red-550 px-2 py-1">
						LISTED
					</div>
				)}
				{collected && (
					<div className="absolute text-xs sm:text-sm top-1/2 w-full text-center transform -translate-y-1/2 bg-black bg-opacity-80 text-blue-550 px-2 py-1">
						CLAIMED
					</div>
				)}
				{liquidated && (
					<div className="absolute text-xs sm:text-sm top-1/2 w-full text-center transform -translate-y-1/2 bg-black bg-opacity-80 text-red-550 px-2 py-1">
						LIQUIDATED
					</div>
				)}

				{item?.level_upgradeable && showLevel && (
					<div className="absolute text-xs sm:text-sm top-1 left-1 flex flex-col z-0">
						<div className="text-center text-xxs font-bold px-1 text-white rounded-sm bg-black bg-opacity-70">
							+{item.level}
						</div>
						{/*<div className="flex justify-center flex-col relative">
							{Array.from({ length: 11 }).map((_, rowIndex) => (
								<div
									key={rowIndex}
									className="absolute"
									style={{
										top: `${rowIndex * 0.3}rem`,
										left: "50%",
										transform: "translateX(-50%)",
										zIndex: 1,
									}}
								>
									<LevelLine
										className={
											item.level <= rowIndex
												? "text-white opacity-40"
												: "text-white"
										}
									/>
								</div>
							))}
						</div>*/}
					</div>
				)}

				{/* Image */}
				<div className="">
					<img
						className={classNames('rounded-sm', {
							'cursor-pointer': onClick,
						})}
						src={`/assets/${item?.name}.jpg`}
						data-tooltip-id={item?.name}
						data-tooltip-content={
							item?.name && item?.name + (item?.level_upgradeable ? ' +' + item.level : '')
						}
					/>
				</div>
				{tooltip && <Tooltip id={item?.name} place="top" />}

				{/* Gradient Overlay */}
				{Number(item?.level) == 0 && (
					<div className="absolute top-0 left-0 w-full h-full border border-gray-550">
						<div className="relative w-full h-full overflow-hidden bg-blue-560  bg-opacity-10 mix-blend-color"></div>
					</div>
				)}
				{Number(item?.level) == 1 && (
					<div className="absolute top-0 left-0 w-full h-full border border-brown-900 border-opacity-30">
						<div className="relative w-full h-full overflow-hidden bg-brown-900  bg-opacity-10 mix-blend-color"></div>
					</div>
				)}
				{Number(item?.level) == 2 && (
					<div className="absolute top-0 left-0 w-full h-full border border-brown-900 border-opacity-30">
						<div className="relative w-full h-full overflow-hidden bg-brown-900  bg-opacity-20 mix-blend-color"></div>
					</div>
				)}
				{Number(item?.level) == 3 && (
					<div className="absolute top-0 left-0 w-full h-full border border-brown-900 border-opacity-40">
						<div className="relative w-full h-full overflow-hidden bg-brown-900  bg-opacity-30 mix-blend-color"></div>
					</div>
				)}
				{Number(item?.level) == 4 && (
					<div className="absolute top-0 left-0 w-full h-full border border-brown-900 border-opacity-50">
						<div className="relative w-full h-full overflow-hidden bg-brown-900  bg-opacity-40 mix-blend-color"></div>
					</div>
				)}

				{Number(item?.level) == 5 && (
					<div className="absolute top-0 left-0 w-full h-full border border-brown-900 border-opacity-60">
						<div className="relative w-full h-full overflow-hidden bg-brown-900  bg-opacity-50 mix-blend-color"></div>
					</div>
				)}
				{Number(item?.level) == 6 && (
					<div className="absolute top-0 left-0 w-full h-full border border-brown-900 border-opacity-70">
						<div className="relative w-full h-full overflow-hidden bg-brown-900  bg-opacity-60 mix-blend-color"></div>
					</div>
				)}
				{Number(item?.level) == 7 && (
					<div className="absolute top-0 left-0 w-full h-full border border-yellow-550 border-opacity-60">
						<div className="relative w-full h-full overflow-hidden bg-yellow-550  bg-opacity-20 mix-blend-color"></div>
						{/*<Sparkles
							color={["#F0A64A"]}
							count={5} // Number of sparkles
							overflowPx={0} // Overflow to cover surrounding area
							fadeOutSpeed={10} // Speed for sparkles to fade out
							flicker={false} // Disable flickering for smoother effect
						/>*/}
					</div>
				)}
				{Number(item?.level) == 8 && (
					<div className="absolute top-0 left-0 w-full h-full border border-yellow-550 border-opacity-70">
						<div className="relative w-full h-full overflow-hidden bg-yellow-550  bg-opacity-40 mix-blend-color"></div>
						{/*<Sparkles
							color={["#CE6DA8"]}
							count={10} // Number of sparkles
							overflowPx={0} // Overflow to cover surrounding area
							fadeOutSpeed={10} // Speed for sparkles to fade out
							flicker={false} // Disable flickering for smoother effect
						/>*/}
					</div>
				)}
				{Number(item?.level) == 9 && (
					<div className="absolute top-0 left-0 w-full h-full border border-yellow-550 border-opacity-100">
						<div className="relative w-full h-full overflow-hidden bg-yellow-550  bg-opacity-70 mix-blend-color"></div>
						{/*<Sparkles
							color={["#11DEEA"]}
							count={15} // Number of sparkles
							overflowPx={0} // Overflow to cover surrounding area
							fadeOutSpeed={10} // Speed for sparkles to fade out
							flicker={false} // Disable flickering for smoother effect
						/>*/}
					</div>
				)}
				{Number(item?.level) == 10 && (
					<div className="absolute top-0 left-0 w-full h-full border border-blue-550 border-opacity-25">
						<div className="absolute w-full h-full overflow-hidden bg-blue-550  bg-opacity-15 mix-blend-color"></div>
						<div className="light-ray-container">
							<div className="ray-thin"></div>
							<Sparkles
								color={['#11DEEA']}
								count={15} // Number of sparkles
								overflowPx={0} // Overflow to cover surrounding area
								fadeOutSpeed={10} // Speed for sparkles to fade out
								flicker={false} // Disable flickering for smoother effect
							/>
						</div>
					</div>
				)}
				{(Number(item?.level) == 11 || item?.item_type == 'Jewel') && (
					<div className="absolute top-0 left-0 w-full h-full border border-blue-550 border-opacity-50">
						<div className="absolute w-full h-full overflow-hidden bg-blue-550  bg-opacity-30 mix-blend-color"></div>
						<div className="light-ray-container">
							<div className="ray"></div>
							<div className="ray-thin"></div>
							<Sparkles
								color={['#11DEEA']}
								count={15} // Number of sparkles
								overflowPx={0} // Overflow to cover surrounding area
								fadeOutSpeed={10} // Speed for sparkles to fade out
								flicker={false} // Disable flickering for smoother effect
							/>
						</div>
					</div>
				)}

				{(Number(item?.level) == 12) && (
					<div className="absolute top-0 left-0 w-full h-full border border-blue-550 border-opacity-70">
						<div className="absolute w-full h-full overflow-hidden bg-blue-550  bg-opacity-50 mix-blend-color"></div>
						<div className="light-ray-container">
							<div className="ray"></div>
							<div className="ray-thin"></div>
							<Sparkles
								color={['#11DEEA']}
								count={30} // Number of sparkles
								overflowPx={0} // Overflow to cover surrounding area
								fadeOutSpeed={10} // Speed for sparkles to fade out
								flicker={false} // Disable flickering for smoother effect
							/>
						</div>
					</div>
				)}

				{(Number(item?.level) == 13) && (
					<div className="absolute top-0 left-0 w-full h-full border border-purple-900 border-opacity-30">
						<div className="absolute w-full h-full overflow-hidden bg-purple-900  bg-opacity-15 mix-blend-color"></div>
						<div className="light-ray-container">
							<div className="ray"></div>
							<div className="ray-thin"></div>
							<Sparkles
								color={['#CC00FF']}
								count={30} // Number of sparkles
								overflowPx={0} // Overflow to cover surrounding area
								fadeOutSpeed={10} // Speed for sparkles to fade out
								flicker={false} // Disable flickering for smoother effect
							/>
						</div>
					</div>
				)}

				{(Number(item?.level) == 14) && (
					<div className="absolute top-0 left-0 w-full h-full border border-purple-900 border-opacity-50">
						<div className="absolute w-full h-full overflow-hidden bg-purple-900  bg-opacity-30 mix-blend-color"></div>
						<div className="light-ray-container">
							<div className="ray"></div>
							<div className="ray-thin"></div>
							<Sparkles
								color={['#CC00FF']}
								count={30} // Number of sparkles
								overflowPx={0} // Overflow to cover surrounding area
								fadeOutSpeed={10} // Speed for sparkles to fade out
								flicker={false} // Disable flickering for smoother effect
							/>
						</div>
					</div>
				)}

				{(Number(item?.level) == 15) && (
					<div className="absolute top-0 left-0 w-full h-full border border-purple-900 border-opacity-75">
						<div className="absolute w-full h-full overflow-hidden bg-purple-900  bg-opacity-50 mix-blend-color"></div>
						<div className="light-ray-container">
							<div className="ray"></div>
							<div className="ray-thin"></div>
							<Sparkles
								color={['#CC00FF']}
								count={30} // Number of sparkles
								overflowPx={0} // Overflow to cover surrounding area
								fadeOutSpeed={10} // Speed for sparkles to fade out
								flicker={false} // Disable flickering for smoother effect
							/>
						</div>
					</div>
				)}
			</div>
		);
	}
);

ItemThumbnail.displayName = 'ItemThumbnail';

export default React.memo(ItemThumbnail);

// src/pages/Lobby.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eventCloud } from '../eventCloud';
import { Fighter, Combat } from '../store/types/fighterTypes';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../components/Button/Button';
import { HealthManaIndicator } from '../components/Fighter/HealthManaIndicator';
import classNames from 'classnames';
import {
	addActiveCombat,
	removeActiveCombat,
	addFinishedCombat,
	setActiveCombats,
	setFinishedCombats,
} from '../store/reducers/combatReducer';

import { FighterPopover } from '../components/Fighter/FighterPopover';

import { ReactComponent as AttackIcon } from '../assets/icons/svg/attack.svg';
import { ReactComponent as SponsorCrown } from '../assets/icons/svg/sponsor-crown.svg';

type LobbyPageProps = {
	fighter: Fighter | null;
};

const LobbyPage: React.FC<LobbyPageProps> = ({ fighter }) => {
	const [selectedTab, setSelectedTab] = useState('active');
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [selectedItem, setSelectedItem] = useState<Fighter | null>(null);

	const dispatch = useDispatch();
	const activeCombats = useSelector((state: any) => state.combat.activeCombats);
	const finishedCombats = useSelector((state: any) => state.combat.finishedCombats);

	const navigate = useNavigate();

	// Create a ref for the scrollable tbody container
	const tbodyContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const combatsSub = eventCloud.activeCombatsStore.subscribe((newCombats) => {
			console.log('lobby active combats updated', newCombats);
			// Object.values(newCombats).forEach((combat: Combat) => {
			// 	dispatch(addActiveCombat(combat));
			// });

			dispatch(setActiveCombats(newCombats));
		});

		const finishedCombatsSub = eventCloud.finishedCombatsStore.subscribe((newCombats) => {
			// newCombats.forEach((combat: Combat) => {
			//     dispatch(removeActiveCombat(combat.id));
			// 	dispatch(addFinishedCombat(combat));
			// });
			dispatch(setFinishedCombats(newCombats));
		});

		// Cleanup subscription on unmount
		return () => {
			combatsSub.unsubscribe();
			finishedCombatsSub.unsubscribe();
		};
	}, []);

	const handleFight = () => {
		eventCloud.sendFight();
		// Scroll the tbody container to the top after sending a fight
		if (tbodyContainerRef.current) {
			tbodyContainerRef.current.scrollTo({
				top: 0,
				behavior: 'smooth', // Optional for smooth scrolling
			});
		}
	};

	const handleJoin = (combat_id: string) => {
		eventCloud.sendJoin(combat_id);
	};

	const handleLeave = (combat_id: string) => {
		eventCloud.sendLeave(combat_id);
	};

	return (
		<div className="h-full overflow-x-auto">
			<FighterPopover
				anchorEl={anchorEl}
				fighter={selectedItem}
				hideStats={false}
				statsVertical={true}
				hideHealth={false}
				onClose={() => {
					setSelectedItem(null);
					setAnchorEl(null);
				}}
			/>
			<div className="flex flex-col">
				<div className="h-full flex flex-col">
					<div
						className="flex-1 overflow-y-auto"
						ref={tbodyContainerRef} // Attach the ref here
					>
						<table className="table-fixed">
							<thead className="bg-gray-550 bg-opacity-50 text-gray-200 font-bold text-xxs border-b border-gray-550 h-[27px]">
								<tr>
									<th className="text-left min-w-[60px] pl-2">Time</th>
									<th className="text-left min-w-[80px]">Status</th>
									<th className="text-center w-[80px]">Type</th>
									<th className="text-center w-[100px]">Levels</th>
									<th className="text-left min-w-[600px] w-full">Participants</th>
									<th className="text-right w-[80px] px-2">Wager</th>
									<th className="text-right min-w-[150px] px-2">Reward</th>
									<th className="text-right w-[80px] px-2">Round</th>
									<th className="text-center w-[80px] px-2">Action</th>
								</tr>
							</thead>
							<tbody className="text-white text-xs font-regular bg-gray-550">
								{Object.values(activeCombats as Record<string, Combat>)
									.slice()
									.sort((a: Combat, b: Combat) => {
										if (!a.started && b.started) return -1;
										if (a.started && !b.started) return 1;
										return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
									})
									.map((combat: Combat) => (
										<tr key={combat.id} className="border-t border-black">
											<td className="w-[60px] pl-2 text-gray-200 font-mono text-m">
												{formatTimestampToLocaleTime(combat.created_at)}
											</td>
											<td
												className={classNames(
													`w-[60px] font-bold ${
														!combat.started ? 'text-green-500' : 'text-yellow-550'
													}`
												)}
											>
												{!combat.started ? 'PEND' : 'PROG'}
											</td>
											<td className="text-center w-[80px]">1 v 1</td>
											<td className="text-center w-[100px] font-mono text-m px-2">
												<div className="flex justify-between">
													<div className="w-[40px]">
														{Math.max(0, combat.maker.level - 1)}
													</div>
													<div className="flex-grow">-</div>
													<div className="w-[40px]">{combat.maker.level + 1}</div>
												</div>
											</td>
											<td>
												<div className="relative flex items-center mr-2 space-x-2 py-1">
													<div className="flex-grow flex font-bold justify-between ">
														<div
															className="flex items-center cursor-pointer gap-x-1"
															onClick={(e: any) => {
																setSelectedItem(combat.maker);
																setAnchorEl(e.currentTarget);
															}}
														>
															<div className="w-[50px]">
																<HealthManaIndicator
																	showManaNumber={false}
																	showHealthNumber={false}
																	fighter={combat.maker}
																/>
															</div>
															{combat.maker.name}{' '}
															<span className="text-blue-550">
																[{combat.maker.level}]
															</span>
															{combat.maker.sponsor_expires &&
																new Date(combat.maker.sponsor_expires) > new Date() && (
																	<SponsorCrown width={13} height={13} />
																)}
															{combat.maker_wager > 0 && (
																<span className="text-yellow-500 ml-1">
																	[{combat.maker_wager}]
																</span>
															)}
															{/*{combat.maker.ig && (
																<span className="text-brown-900 ml-1">[G]</span>
															)}*/}
														</div>

														<div className="absolute left-1/2 transform -translate-x-1/2 text-gray-200">
															VS
														</div>

														{combat.taker ? (
															<div
																className="flex items-center cursor-pointer gap-x-1"
																onClick={(e: any) => {
																	setSelectedItem(combat.taker);
																	setAnchorEl(e.currentTarget);
																}}
															>
																{combat.taker_wager > 0 && (
																	<span className="text-yellow-500 ml-1">
																		[{combat.taker_wager}]
																	</span>
																)}
																{combat.taker.sponsor_expires &&
																	new Date(combat.taker.sponsor_expires) >
																		new Date() && (
																		<SponsorCrown width={13} height={13} />
																	)}
																{combat.taker.name}{' '}
																<span className="text-blue-550">
																	[{combat.taker.level}]
																</span>
																{/*{combat.taker.ig && (
																	<span className="text-brown-900 ml-1">[G]</span>
																)}*/}
																<div className="w-[50px]">
																	<HealthManaIndicator
																		showManaNumber={false}
																		showHealthNumber={false}
																		fighter={combat.taker}
																	/>
																</div>
															</div>
														) : (
															<div className="text-gray-200">N/A</div>
														)}
													</div>
												</div>
											</td>
											<td
												className={classNames(
													`text-right w-[80px] px-2 ${Math.min(combat.maker_wager, combat.taker_wager) > 0 ? 'text-yellow-500' : 'text-gray-200'}`
												)}
											>
												{Math.min(combat.maker_wager, combat.taker_wager)}
											</td>
											<td className="text-right w-[120px] px-2"></td>
											<td className="text-right w-[80px] px-2">
												{!combat.started ? (
													<div className="text-gray-200">0</div>
												) : (
													<div className="text-gray-200">
														{!combat?.closed_rounds
															? 1
															: Number(combat?.closed_rounds?.length) + 1}
													</div>
												)}
											</td>
											<td className="text-right w-[80px] px-2">
												<div>
													{!combat.started && combat.maker.id === fighter?.id ? (
														<Button
															variant="red-trans"
															onClick={() => handleLeave(combat.id)}
															className="py-1 w-[60px]"
														>
															ABORT
														</Button>
													) : (
														<Button
															variant={
																combat.started
																	? 'blue-trans'
																	: fighter?.level !== undefined &&
																		  combat?.maker?.level !== undefined &&
																		  fighter.level >= combat.maker.level - 1 &&
																		  fighter.level <= combat.maker.level + 1
																		? 'green-trans'
																		: 'gray-dis'
															}
															onClick={() => {
																if (!combat.started) {
																	handleJoin(combat.id);
																} else {
																	navigate('/combat/' + combat.id);
																}
															}}
															disabled={
																!combat.started &&
																combat.maker.id === eventCloud.selectedFighter?.id
															}
															className="py-1 w-[60px]"
														>
															{combat?.started
																? 'WATCH'
																: fighter?.level !== undefined &&
																	  combat?.maker?.level !== undefined &&
																	  fighter.level >= combat.maker.level - 1 &&
																	  fighter.level <= combat.maker.level + 1
																	? 'JOIN'
																	: 'N/A'}
														</Button>
													)}
												</div>
											</td>
										</tr>
									))}

								{finishedCombats.map((combat: Combat) => (
									<tr key={combat.id} className="border-t border-black">
										<td className=" w-[60px] pl-2 text-gray-200 font-mono text-m">
											{formatTimestampToLocaleTime(combat.finished_at)}
										</td>
										<td
											className={classNames(
												` w-[60px] font-bold ${
													combat.winner
														? 'text-blue-550'
														: combat.is_abandoned
															? 'text-gray-200'
															: 'text-red-550'
												}`
											)}
										>
											{combat.winner ? 'VICT' : combat.is_abandoned ? 'ABAN' : 'DRAW'}
										</td>
										<td className="text-center w-[80px]">1 v 1</td>
										<td className="text-center w-[100px] font-mono text-m px-2">
											<div className="flex justify-between">
												<div className="w-[40px]">{Math.max(0, combat.maker.level - 1)}</div>
												<div className="flex-grow">-</div>
												<div className="w-[40px]">{combat.maker.level + 1}</div>
											</div>
										</td>
										<td>
											<div className="relative flex items-center mr-2 space-x-2 py-1">
												<div className="flex-grow flex font-bold justify-between">
													<div
														className="flex items-center cursor-pointer gap-x-1"
														onClick={(e: any) => {
															setSelectedItem(combat.maker);
															setAnchorEl(e.currentTarget);
														}}
													>
														<div className="w-[50px]">
															<HealthManaIndicator
																showManaNumber={false}
																showHealthNumber={false}
																fighter={combat.maker}
															/>
														</div>
														{combat.maker.name}{' '}
														<span className="text-blue-550">[{combat.maker.level}]</span>
														{combat.maker.sponsor_expires &&
															new Date(combat.maker.sponsor_expires) > new Date() && (
																<SponsorCrown width={13} height={13} />
															)}
														{combat.maker_wager > 0 && (
															<span className="text-yellow-500 ml-1">
																[{combat.maker_wager}]
															</span>
														)}
														{combat.winner && combat.winner.id == combat.maker.id && (
															<AttackIcon
																className="text-red-550"
																width={15}
																height={15}
															/>
														)}
														{/*{combat.maker.ig && (
															<span className="text-brown-900 ml-1">[G]</span>
														)}*/}
													</div>

													<div className="absolute left-1/2 transform -translate-x-1/2 text-gray-200">
														VS
													</div>
													<div>
														<div
															className="flex items-center cursor-pointer gap-x-1"
															onClick={(e: any) => {
																setSelectedItem(combat.taker);
																setAnchorEl(e.currentTarget);
															}}
														>
															{combat.winner && combat.winner.id == combat.taker.id && (
																<AttackIcon
																	className="text-red-550"
																	width={15}
																	height={15}
																/>
															)}
															{combat.taker_wager > 0 && (
																<span className="text-yellow-500 ml-1">
																	[{combat.taker_wager}]
																</span>
															)}
															{combat.taker.sponsor_expires &&
																new Date(combat.taker.sponsor_expires) > new Date() && (
																	<SponsorCrown width={13} height={13} />
																)}
															{combat.taker.name}{' '}
															<span className="text-blue-550">
																[{combat.taker.level}]
															</span>
															{/*{combat.taker.ig && (
																<span className="text-brown-900 ml-1">[G]</span>
															)}*/}
															<div className="w-[50px]">
																<HealthManaIndicator
																	showManaNumber={false}
																	showHealthNumber={false}
																	fighter={combat.taker}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</td>
										<td
											className={classNames(
												`text-right w-[80px] px-2 ${Math.min(combat.maker_wager, combat.taker_wager) > 0 ? 'text-yellow-500' : 'text-gray-200'}`
											)}
										>
											{Math.min(combat.maker_wager, combat.taker_wager)}
										</td>
										<td className="text-right w-[120px] px-2 text-blue-550">
											{combat.dropped_credits > 0 ? (
												<span>{combat.dropped_credits} CR</span>
											) : (
												combat.dropped_item && <span>{combat.dropped_item.name}</span>
											)}
										</td>
										<td className="text-right w-[80px] px-2">
											<div className="text-gray-200">
												{!combat?.closed_rounds ? 0 : Number(combat?.closed_rounds?.length)}
											</div>
										</td>

										<td className="text-right w-[80px] px-2">
											<div>
												<Button
													variant="gray-trans"
													onClick={() => navigate('/combat/' + combat.id)}
													className="py-1 w-[60px]"
												>
													VIEW
												</Button>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

function formatTimestampToLocaleTime(timestamp: number) {
	return new Date(timestamp).toLocaleTimeString(undefined, {
		hour: '2-digit',
		minute: '2-digit',
	});
}

export default LobbyPage;

// src/store/reducers/inventoryReducer.ts

import { Item } from '../types/fighterTypes';

// Action types
const ADD_ITEM = 'ADD_ITEM';
const REMOVE_ITEM = 'REMOVE_ITEM';
const UPDATE_ITEM = 'UPDATE_ITEM';
const SET_INVENTORY = 'SET_INVENTORY';

// Action creators
export const addItem = (item: Item) => ({
	type: ADD_ITEM,
	payload: item,
});

export const removeItem = (itemId: string) => ({
	type: REMOVE_ITEM,
	payload: itemId,
});

export const updateItem = (item: Item) => ({
	type: UPDATE_ITEM,
	payload: item,
});

export const setInventory = (items: Item[]) => ({
	type: SET_INVENTORY,
	payload: items,
});

// Initial state
const initialState = {
	inventory: [] as Item[],
};

// Reducer
const inventoryReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case ADD_ITEM:
			return {
				...state,
				inventory: [...state.inventory, action.payload],
			};
		case REMOVE_ITEM:
			return {
				...state,
				inventory: state.inventory.filter((item) => item.id !== action.payload),
			};
		case UPDATE_ITEM:
			return {
				...state,
				inventory: state.inventory.map((item) => (item.id === action.payload.id ? action.payload : item)),
			};
		case SET_INVENTORY:
			return {
				...state,
				inventory: action.payload,
			};
		default:
			return state;
	}
};

export default inventoryReducer;

// src/pages/LeaderboardPage.tsx

import React from 'react';
import { eventCloud } from '../eventCloud';
import { Button } from '../components/Button/Button';
import classNames from 'classnames';
import Leaderboard from '../components/Shared/Leaderboard';

const LeaderboardPage: React.FC = () => {
	return (
		<div className="h-full">
			<Leaderboard />
		</div>
	);
};

export default LeaderboardPage;

// Login.tsx
import React from 'react';
import { ReactComponent as MetaMaskIcon } from '../../assets/icons/svg/metamask.svg';

import classNames from 'classnames';

const Login: React.FC = () => {
	return (
		<div className="p-10">
			<div className="flex justify-center">
				<MetaMaskIcon width={150} height={150} />
			</div>
			<div className="mt-10 flex justify-center text-center">
				Your session has expired. Please sign a new login message in your MetaMask.
			</div>
		</div>
	);
};

export default Login;

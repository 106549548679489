import classNames from 'classnames';
import React, { forwardRef, useEffect, useState } from 'react';
import { Combat, Fighter } from '../../store/types/fighterTypes';
import { eventCloud } from '../../eventCloud';
import { Button } from '../Button/Button';
import { useNavigate } from 'react-router-dom';

import { RoundTargetHits } from '../Combat/RoundTargetHits';
import { ReactComponent as CreditsIconBlack } from '../../assets/icons/svg/credits.svg';
import { ReactComponent as CreditsIconBlue } from '../../assets/icons/svg/credits-blue.svg';

type CombatPrefightScreenProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	combat: Combat;
};

export const CombatPrefightScreen = forwardRef<HTMLButtonElement, CombatPrefightScreenProps>(
	({ combat, ...rest }, ref) => {
		const navigate = useNavigate();
		const [countdown, setCountdown] = useState<number>(0);

		useEffect(() => {
			// Assuming combat.starting_at is a Date object or timestamp in milliseconds
			const startAt = new Date(combat.starting_at).getTime();
			const now = new Date().getTime();
			const initialDistance = startAt - now;

			// Set the countdown immediately with the initial value
			if (initialDistance < 0) {
				setCountdown(0);
			} else {
				setCountdown(Math.floor(initialDistance / 1000));
			}

			const interval = setInterval(() => {
				const now = new Date().getTime();
				const distance = startAt - now;

				if (distance < 0) {
					// Stop the countdown if combat has started
					clearInterval(interval);
					setCountdown(0);
				} else {
					// Update countdown in seconds
					setCountdown(Math.floor(distance / 1000));
				}
			}, 1000); // Update every second

			// Clean up the interval on component unmount
			return () => clearInterval(interval);
		}, [combat.starting_at]);

		const handleSetWager = (wager: number) => {
			eventCloud.sendWager(combat.id, wager);
		};

		const getWager = (): number => {
			if (eventCloud.selectedFighter?.id === combat.maker_id) {
				return combat.maker_wager;
			} else if (eventCloud.selectedFighter?.id === combat.taker_id) {
				return combat.taker_wager;
			}

			return 0;
		};

		const getOppWager = (): number => {
			if (eventCloud.selectedFighter?.id === combat.maker_id) {
				return combat.taker_wager;
			} else if (eventCloud.selectedFighter?.id === combat.taker_id) {
				return combat.maker_wager;
			}

			return 0;
		};

		return (
			<div
				className="w-full h-full p-4 bg-cover bg-center border border-gray-550 rounded-sm"
				style={{
					backgroundImage: "url('/assets/--combat-abandonned-bg.png')",
				}}
			>
				<div className="flex flex-col h-full justify-between gap-y-2">
					<div className="flex flex-col items-center justify-center flex-grow">
						{' '}
						{/* Add flex, justify-center, and items-center */}
						<div className="text-xxs md:text-2xl text-center">Battle starts in</div>
						<div className="text-4xl text-white font-bold mt-2">{countdown}s</div>
					</div>

					<div className="text-center font-bold text-yellow-500">PLACE BET?</div>
					<div className="text-center font-bold text-white text-xxs">
						The smaller of the two fighters wagers will be the active bet
					</div>
					<div className="flex flex-col gap-y-1">
						<div className="flex gap-x-1">
							<div className="w-1/3">
								<Button
									variant={
										getWager() == 0 ? 'basic' : getOppWager() == 0 ? 'yellow-trans' : 'blue-trans'
									}
									onClick={() => handleSetWager(0)}
									disabled={getWager() == 0}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 0 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>0</div>
									</div>
								</Button>
							</div>
							{/*<div className="w-1/4">
								<Button variant={getWager() == 1 ? 'basic' : 'blue-trans'} onClick={() => handleSetWager(1)}>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 1 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}

										<div>1</div>
									</div>
								</Button>
							</div>
							<div className="w-1/4">
								<Button
									variant={getWager() == 5 ? 'basic' : 'blue-trans'}
									onClick={() => handleSetWager(5)}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 5 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>5</div>
									</div>
								</Button>
							</div>*/}
							<div className="w-1/3">
								<Button
									variant={
										getWager() == 10 ? 'basic' : getOppWager() == 10 ? 'yellow-trans' : 'blue-trans'
									}
									onClick={() => handleSetWager(10)}
									disabled={getWager() == 10}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 10 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>10</div>
									</div>
								</Button>
							</div>
							<div className="w-1/3">
								<Button
									variant={
										getWager() == 50 ? 'basic' : getOppWager() == 50 ? 'yellow-trans' : 'blue-trans'
									}
									onClick={() => handleSetWager(50)}
									disabled={getWager() == 50}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 50 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>50</div>
									</div>
								</Button>
							</div>
						</div>

						<div className="flex gap-x-1">
							<div className="w-1/3">
								<Button
									variant={
										getWager() == 100
											? 'basic'
											: getOppWager() == 100
												? 'yellow-trans'
												: 'blue-trans'
									}
									onClick={() => handleSetWager(100)}
									disabled={getWager() == 100}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 100 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>100</div>
									</div>
								</Button>
							</div>
							{/*<div className="w-1/4">
								<Button
									variant={getWager() == 250 ? 'basic' : 'blue-trans'}
									onClick={() => handleSetWager(250)}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 250 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>250</div>
									</div>
								</Button>
							</div>*/}
							<div className="w-1/3">
								<Button
									variant={
										getWager() == 500
											? 'basic'
											: getOppWager() == 500
												? 'yellow-trans'
												: 'blue-trans'
									}
									onClick={() => handleSetWager(500)}
									disabled={getWager() == 500}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 500 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>500</div>
									</div>
								</Button>
							</div>
							<div className="w-1/3">
								<Button
									variant={
										getWager() == 1000
											? 'basic'
											: getOppWager() == 1000
												? 'yellow-trans'
												: 'blue-trans'
									}
									onClick={() => handleSetWager(1000)}
									disabled={getWager() == 1000}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 1000 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>1K</div>
									</div>
								</Button>
							</div>
						</div>

						<div className="flex gap-x-1">
							<div className="w-1/3">
								<Button
									variant={
										getWager() == 5000
											? 'basic'
											: getOppWager() == 5000
												? 'yellow-trans'
												: 'blue-trans'
									}
									onClick={() => handleSetWager(5000)}
									disabled={getWager() == 5000}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 5000 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>5K</div>
									</div>
								</Button>
							</div>
							<div className="w-1/3">
								<Button
									variant={
										getWager() == 10000
											? 'basic'
											: getOppWager() == 10000
												? 'yellow-trans'
												: 'blue-trans'
									}
									onClick={() => handleSetWager(10000)}
									disabled={getWager() == 10000}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 10000 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>10K</div>
									</div>
								</Button>
							</div>
							<div className="w-1/3">
								<Button
									variant={
										getWager() == 25000
											? 'basic'
											: getOppWager() == 25000
												? 'yellow-trans'
												: 'blue-trans'
									}
									onClick={() => handleSetWager(25000)}
									disabled={getWager() == 25000}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 25000 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>25K</div>
									</div>
								</Button>
							</div>
						</div>

						<div className="flex gap-x-1">
							<div className="w-1/3">
								<Button
									variant={
										getWager() == 50000
											? 'basic'
											: getOppWager() == 50000
												? 'yellow-trans'
												: 'blue-trans'
									}
									onClick={() => handleSetWager(50000)}
									disabled={getWager() == 50000}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 50000 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>50K</div>
									</div>
								</Button>
							</div>
							<div className="w-1/3">
								<Button
									variant={
										getWager() == 100000
											? 'basic'
											: getOppWager() == 100000
												? 'yellow-trans'
												: 'blue-trans'
									}
									onClick={() => handleSetWager(100000)}
									disabled={getWager() == 100000}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 100000 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>100K</div>
									</div>
								</Button>
							</div>
							<div className="w-1/3">
								<Button
									variant={
										getWager() == 500000
											? 'basic'
											: getOppWager() == 500000
												? 'yellow-trans'
												: 'blue-trans'
									}
									onClick={() => handleSetWager(500000)}
									disabled={getWager() == 500000}
								>
									<div className="flex gap-x-1 items-center justify-center">
										{getWager() == 500000 ? (
											<CreditsIconBlack width={18} height={18} />
										) : (
											<CreditsIconBlue width={18} height={18} />
										)}
										<div>500K</div>
									</div>
								</Button>
							</div>
						</div>
					</div>

					<div className="text-xxs text-center">
						If you <span className="text-green-500">WIN</span>, you&apos;ll gain an extra{' '}
						<span className="text-green-500 font-bold">{getWager() * 0.9} CR</span>. <br />
						If you <span className="text-red-550">LOSE</span>, you&apos;ll lose{' '}
						<span className="text-red-550 font-bold">{getWager()} CR</span>. <br />
						In case of <span className="text-yellow-500">DRAW</span>, you&apos;ll lose{' '}
						<span className="text-yellow-500 font-bold">{getWager() * 0.1} CR</span>.
					</div>
					<div className="text-xxs text-center text-green-500 bg-green-500 bg-opacity-10 border border-green-500 p-2">
						This bet will not impact your usual rewards of credits or items from a victory.
					</div>
				</div>
			</div>
		);
	}
);

CombatPrefightScreen.displayName = 'CombatPrefightScreen';

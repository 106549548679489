// src/pages/HistoryPage.tsx

import React, { useState, useEffect, useRef } from 'react';
import { eventCloud } from '../eventCloud';
import { Fighter, Combat } from '../store/types/fighterTypes';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../components/Button/Button';
import classNames from 'classnames';
import { getBackendUrl } from '../helpers/getBackendUrl';

import { ReactComponent as AttackIcon } from '../assets/icons/svg/attack.svg';

type HistoryPageProps = {
	fighter: Fighter | null;
};

const HistoryPage: React.FC<HistoryPageProps> = ({ fighter }) => {
	const [finishedCombats, setFinishedCombats] = useState<Combat[] | null>(null);

	const navigate = useNavigate();

	useEffect(() => {
		eventCloud.requestCombatsHistory();
	}, [fighter]);

	useEffect(() => {
		const handleCombatsHistory = (combatHistory: Combat[]) => {
			console.log('combatHistory', combatHistory);
			setFinishedCombats(combatHistory);
		};

		eventCloud.on('combats_history', handleCombatsHistory);

		return () => {
			eventCloud.off('combats_history', handleCombatsHistory);
		};
	}, []);

	return (
		<div className="h-full">
			<div className="flex flex-col h-full">
				<div className="flex flex-col">
					<div className="h-full">
						<div className="h-full flex flex-col m-5">
							<table className="w-full table-fixed">
								<thead className="bg-gray-550 bg-opacity-50 text-gray-200 font-bold text-xxs border-b border-gray-550 h-[27px]">
									<tr>
										<th className="text-left w-[120px] pl-2">Time</th>
										<th className="text-left w-[240px]">Status</th>
										<th className="text-center w-[80px]">Type</th>
										<th className="text-center w-[100px]">Levels</th>
										<th className="text-left">Participants</th>
										<th className="text-right w-[80px] px-2">Round</th>
										<th className="text-center w-[80px] px-2">Action</th>
									</tr>
								</thead>
							</table>

							{!finishedCombats ? (
								<div className="text-center text-gray-200 text-m m-10">Nothing to show</div>
							) : (
								<div className="flex-1 overflow-y-auto">
									<table className="w-full table-fixed">
										<tbody className="text-white text-xs font-regular bg-gray-550">
											{finishedCombats.map((combat) => (
												<tr key={combat.id} className="border-t border-black">
													<td className=" w-[140px] pl-2 text-gray-200 font-mono text-m">
														{formatTimestampToLocaleTime(combat.finished_at)}
													</td>
													<td
														className={classNames(
															` w-[240px] font-bold ${
																combat.winner
																	? combat.winner.id == fighter?.id
																		? 'text-green-500'
																		: 'text-red-550'
																	: combat.is_abandoned
																		? 'text-gray-200'
																		: 'text-yellow-500'
															}`
														)}
													>
														{combat.winner
															? combat.winner.id == fighter?.id
																? 'WIN'
																: 'LOSS'
															: combat.is_abandoned
																? 'ABANDONED'
																: 'DRAW'}
													</td>
													<td className="text-center w-[80px]">1 v 1</td>
													<td className="text-center w-[100px] font-mono text-m px-2">
														<div className="flex justify-between">
															<div className="w-[40px]">
																{Math.max(0, combat.maker.level - 1)}
															</div>
															<div className="flex-grow">-</div>
															<div className="w-[40px]">{combat.maker.level + 1}</div>
														</div>
													</td>
													<td>
														<div className="relative flex items-center mr-2 space-x-2 py-1">
															<div className="flex-grow flex font-bold justify-between">
																<div className="flex items-center">
																	{combat.winner &&
																		combat.winner.id == combat.maker.id && (
																			<AttackIcon
																				className="text-red-550"
																				width={15}
																				height={15}
																			/>
																		)}
																	{combat.maker.name}{' '}
																	<span className="text-blue-550">
																		[{combat.maker.level}]
																	</span>
																</div>

																<div className="absolute left-1/2 transform -translate-x-1/2 text-gray-200">
																	VS
																</div>

																{combat.taker ? (
																	<div>
																		<div className="flex items-center">
																			{combat.winner &&
																				combat.winner.id == combat.taker.id && (
																					<AttackIcon
																						className="text-red-550"
																						width={15}
																						height={15}
																					/>
																				)}
																			{combat.taker.name}{' '}
																			<span className="text-blue-550">
																				[{combat.taker.level}]
																			</span>
																		</div>
																	</div>
																) : (
																	<div>AWAITING OPPONENT</div>
																)}
															</div>
														</div>
													</td>
													<td className="text-right w-[80px] px-2">
														<div className="text-gray-200">
															{!combat?.closed_rounds
																? 0
																: Number(combat?.closed_rounds?.length)}
														</div>
													</td>
													<td className="text-right w-[80px] px-2">
														<div>
															<Button
																variant="gray-trans"
																onClick={() => navigate('/combat/' + combat.id)}
																className="py-1 w-[60px]"
															>
																VIEW
															</Button>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

function formatTimestampToLocaleTime(timestamp: number) {
	return new Date(timestamp).toLocaleTimeString(undefined, {
		day: '2-digit',
		month: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	});
}

export default HistoryPage;

// store/index.ts

import { createStore, combineReducers } from 'redux';
import combatReducer from './reducers/combatReducer';
import inventoryReducer from './reducers/inventoryReducer';

const rootReducer = combineReducers({
	combat: combatReducer,
	inventory: inventoryReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const store = createStore(
	rootReducer,
	(window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;

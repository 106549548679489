// Tabs.tsx

import React, { forwardRef, useState, useEffect } from 'react';
import classNames from 'classnames';

type TabsProps = {
	items: string[];
	onSelect: any;
	noLine?: boolean;
	selectedItem?: string;
	variant?: 'text' | 'block';
};

export const Tabs = forwardRef<HTMLDivElement, TabsProps>(
	({ items, onSelect, noLine, variant = 'text', selectedItem, ...rest }, ref) => {
		const [selectedTab, setSelectedTab] = useState<string | null>(null);

		useEffect(() => {
			// Auto-select the first non-divider item on initial render
			if (selectedItem) {
				setSelectedTab(selectedItem);
				onSelect(selectedItem);
			} else {
				const firstItem = items.find((item) => item !== 'divider') || null;
				if (firstItem) {
					setSelectedTab(firstItem);
					onSelect(firstItem);
				}
			}
		}, []);

		return (
			<div
				className={`font-bold text-gray-200 text-xxs md:text-sm pb-2 flex overflow-x-auto ${variant == 'block' && 'gap-x-1'}`}
			>
				{items.map((tab, index) => (
					<React.Fragment key={index}>
						{tab == 'divider' ? (
							<div className="flex">
								<div
									className={classNames('text-gray-200 pr-2 pb-1 pl-1', {
										'border-r border-b border-gray-540': !noLine,
									})}
								>
									&nbsp;
								</div>
								<div
									className={classNames('text-gray-200 pr-2 pb-1 pl-1', {
										'border-gray-540 border-b': !noLine,
									})}
								>
									&nbsp;
								</div>
							</div>
						) : (
							<>
								{index > 0 && variant == 'text' && (
									<div
										className={classNames('px-1', {
											'border-b border-gray-550': !noLine,
										})}
									></div>
								)}

								<div
									onClick={() => {
										setSelectedTab(tab);
										onSelect(tab);
									}}
									className={classNames(
										`cursor-pointer select-none ${variant == 'block' && 'text-center bg-opacity-10 w-full md:w-auto'}`,
										{
											'text-blue-550 border-b border-blue-550':
												selectedTab === tab && variant == 'text',
										},
										{
											'text-gray-200 border-b border-gray-550':
												selectedTab !== tab && !noLine && variant == 'text',
										},
										{
											'text-blue-550 bg-blue-550 px-4 py-1 md:py-4':
												selectedTab === tab && variant == 'block',
										},
										{
											'text-white bg-gray-200 px-4 py-1 md:py-4':
												selectedTab !== tab && variant == 'block',
										}
									)}
								>
									{tab}
								</div>
								{variant == 'text' && (
									<div
										className={classNames('px-2', {
											'border-gray-550 border-b': !noLine,
										})}
									></div>
								)}
							</>
						)}
					</React.Fragment>
				))}
				{variant == 'text' && (
					<div
						className={classNames('flex-grow', {
							'border-b border-gray-550': !noLine,
						})}
					></div>
				)}
			</div>
		);
	}
);

Tabs.displayName = 'Tabs';

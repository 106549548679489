import React, { useState, useEffect } from 'react';
import { eventCloud } from '../../eventCloud';
import { Fighter } from '../../store/types/fighterTypes';
import { Button } from '../Button/Button';
import classNames from 'classnames';
import { getBackendUrl } from '../../helpers/getBackendUrl';
import { FighterPopover } from '../Fighter/FighterPopover';

import { ReactComponent as AttackIcon } from '../assets/icons/svg/attack.svg';

const Leaderboard: React.FC = () => {
	const [leaderboard, setLeaderboard] = useState<Fighter[]>([]);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [selectedItem, setSelectedItem] = useState<Fighter | null>(null);

	useEffect(() => {
		fetchLeaderboard();
	}, []);

	const fetchLeaderboard = () => {
		fetch(getBackendUrl() + '/leaderboard')
			.then((response) => {
				if (!response.ok) {
					throw new Error('Failed to fetch leaderboard items');
				}
				return response.json();
			})
			.then((data: any) => {
				setLeaderboard(data.items);
			})
			.catch((error) => {
				console.error('Error fetching leaderboard items:', error);
			});
	};

	return (
		<div className="h-full  text-xxs sm:text-xs overflow-x-auto">
			<FighterPopover
				anchorEl={anchorEl}
				fighter={selectedItem}
				onClose={() => {
					setSelectedItem(null);
					setAnchorEl(null);
				}}
			/>
			<div className="h-full">
				<div className="mt-4 text-xs">TOP PLAYERS</div>
				<div className="h-full flex flex-col mt-4">
					<table className="w-full table-fixed">
						<thead className="bg-gray-550 bg-opacity-50 text-gray-200 font-bold border-b border-gray-550 h-[27px]">
							<tr>
								<th className="text-left w-[120px] pl-2">Ranking</th>
								<th className="text-left">Player</th>
								{/*<th className="text-right w-[120px]">
									Victories
								</th>
								<th className="text-right w-[120px]">
									Defeats
								</th>
								<th className="text-right w-[120px] px-2">
									Draws
								</th>
								<th className="text-right w-[120px] px-2">
									Abandons
								</th>*/}
								<th className="text-right w-[120px] px-2">Experience</th>
							</tr>
						</thead>
					</table>

					{!leaderboard ? (
						<div className="text-center text-gray-200 text-m m-10">Nothing to show</div>
					) : (
						<div className="flex-1">
							<table className="w-full table-fixed">
								<tbody className="text-white text-xs font-regular bg-gray-550">
									{leaderboard.map((item, index) => (
										<tr key={index} className="border-b border-black">
											<td className="text-left w-[120px] p-2 py-4 font-mono">{index + 1}</td>
											<td
												className="p-2 text-left font-bold py-4 cursor-pointer"
												onClick={(e: any) => {
													setSelectedItem(item);
													setAnchorEl(e.currentTarget);
												}}
											>
												{item.name} <span className="text-blue-550">[{item.level}]</span>
											</td>
											{/*<td className="p-2 text-right w-[120px] py-4 font-mono font-bold text-green-500">
												{item.victories}
											</td>
											<td className="p-2 text-right w-[120px] py-4 font-mono font-bold text-red-550">
												{item.defeats}
											</td>
											<td className="p-2 text-right w-[120px] py-4 font-mono font-bold text-yellow-500">
												{item.draws}
											</td>
											<td className="p-2 text-right w-[120px] py-4 font-mono font-bold text-gray-200">
												{item.abandons}
											</td>*/}
											<td className="p-2 text-right w-[120px] py-4 font-mono ">
												{item.experience}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Leaderboard;
